import { createCustomerAccount, deleteCustomerAccount, updateCustomerAccount } from "api/security";
import Delete from "components/global/Delete";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";
import { useEffect, useState } from "react";
import { FaEdit, FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import SecurityColumnHeader from "./SecurityColumnHeader";
import { labelTypes } from "utilities/constants";

export default function Accounts() {
  const securityState = useSelector((state) => state.security);
  const { customerId } = useParams();

  const [accounts, setAccounts] = useState([]);
  const [currentlyEditing, setCurrentlyEditing] = useState({});
  const [addAccountInputValue, setAddAccountInputValue] = useState("");
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [sortParams, setSortParams] = useState({
    sortCol: "name",
    sortAsc: true,
  });

  useEffect(() => {
    setAccounts([...securityState.currentCustomer.accounts]);
  }, [securityState.currentCustomer.accounts]);

  const handleAddNewAccount = async () => {
    await createCustomerAccount(customerId, addAccountInputValue);
    setIsAddingAccount(false);
    setAddAccountInputValue("");
  };

  const handleEditAccountName = async (account) => {
    updateCustomerAccount(customerId, account.id, "name", account.name, currentlyEditing[account.id].name);
    setCurrentlyEditing({
      ...currentlyEditing,
      [account.id]: { ...currentlyEditing[account.id], isEditing: false },
    });
  };

  const handleUpdateSortParams = (sortedData, newSortParams) => {
    setAccounts(sortedData);
    setSortParams(newSortParams);
  };

  const renderAccountsTableBody =
    accounts?.length > 0 &&
    accounts.map((account) => (
      <tr className="security__table__body-row" key={account.id}>
        {currentlyEditing[account.id]?.isEditing ? (
          <td>
            <input
              id="editNameInput"
              type="text"
              value={currentlyEditing[account.id]?.name ?? account.name}
              onChange={(e) =>
                setCurrentlyEditing({
                  ...currentlyEditing,
                  [account.id]: { ...currentlyEditing[account.id], name: e.target.value },
                })
              }
            ></input>
          </td>
        ) : (
          <td className="security__table__body-row-cell link">
            <Link to={`/customers/${securityState.currentCustomer.id}/accounts/${account.id}`}>{account.name}</Link>
          </td>
        )}
        <td className="security__table__body-row-cell ">{account.groupsCount ?? 0}</td>
        <td className="security__table__body-row-cell ">{account.usersCount ?? 0}</td>
        <td className="security__table__body-row-cell ">{account.matterCount ?? 0}</td>
        {currentlyEditing[account.id]?.isEditing ? (
          <td className="security__table__body-row-cell ">
            <button onClick={() => handleEditAccountName(account)}>{t("Confirm")}</button>
          </td>
        ) : (
          <td
            className="security__table__body-row-cell security__table__body-row-cell--edit clickable"
            onClick={() =>
              setCurrentlyEditing({
                ...currentlyEditing,
                [account.id]: { ...currentlyEditing[account.id], isEditing: true },
              })
            }
          >
            <Tooltip content="Edit this Account" direction="left">
              <FaEdit />
            </Tooltip>
          </td>
        )}
        {account.groupsCount > 0 || account.usersCount > 0 || account.matterCount > 0 ? (
          <td className="security__table__body-row-cell ">
            <Tooltip content="Cannot delete this user group as it contains groups, users or records" direction="right">
              <FaTimes className="security__table__body-row-cell--disabled" />
            </Tooltip>
          </td>
        ) : (
          <td className="security__table__body-row-cell ">
            <Delete
              message={"remove this Account"}
              onConfirm={() => deleteCustomerAccount(securityState.currentCustomer.id, account.id)}
            />
          </td>
        )}
      </tr>
    ));

  const renderAccountsTable = (
    <>
      <div className="security__section-title">{t("Accounts")}</div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              <SecurityColumnHeader
                fieldName="name"
                fieldType={labelTypes.STRING}
                displayName="Name"
                tableData={accounts}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="groupsCount"
                fieldType={labelTypes.DECIMAL}
                displayName="Groups"
                tableData={accounts}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="usersCount"
                fieldType={labelTypes.DECIMAL}
                displayName="Users"
                tableData={accounts}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="matterCount"
                fieldType={labelTypes.DECIMAL}
                displayName="Records"
                tableData={accounts}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <th className="security__table__header-row-cell">{t("Edit")}</th>
              <th className="security__table__header-row-cell">{t("Delete")}</th>
            </tr>
          </thead>
          <tbody className="security__table__body">
            {renderAccountsTableBody}
            {isAddingAccount && (
              <tr className="security__table__body-row">
                <td className="security__table__body-row-cell ">
                  <input
                    placeholder="Account Name"
                    id="addNameInput"
                    type="text"
                    onChange={(e) => setAddAccountInputValue(e.target.value)}
                  ></input>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {!isAddingAccount ? (
        <button className="flex-row-center" onClick={() => setIsAddingAccount(true)}>
          {t("Add Account")}
        </button>
      ) : (
        <button onClick={() => handleAddNewAccount()}>{t("Confirm")}</button>
      )}
    </>
  );

  //MAIN RENDER
  return <>{renderAccountsTable}</>;
}
