// Utility functions for the report editor

import { t } from "locale/dictionary";
import store from "redux/store";
import { getLookupValueForField } from "utilities/datafield";
import { getFieldsExcludedByMatterTypes } from "utilities/lookup";
import { getTranslation, setBorderStyle } from "utilities/reportShared";
import { compareByFieldWeighting, filterOutOnId } from "./stringAndArray";
import { FormatTypes } from "./constants";

export const SectionTypeEnum = Object.freeze({
  REPORT_HEADER: 1,
  PAGE_HEADER: 3,
  GROUP_HEADER: 5,
  LINE: 7,
  GROUP_FOOTER: 9,
  PAGE_FOOTER: 11,
  REPORT_FOOTER: 13,
});

export const ReportOutputTypes = Object.freeze({
  DEFAULT: 0,
  EXCEL: 1,
  WORD: 2,
  PDF: 3, // NOT YET IMPLEMENTED
  HTML: 4, // NOT YET IMPLEMENTED
});

export const ResultSetTypes = Object.freeze({
  TABLE: 0,
  LIST: 1,
  PIVOT: 2,
  MAP: 3,
  EXCEL: 4,
  WORD: 5,
  HTML: 6,
  EMAIL: 7,
});

export const OwnerTypes = Object.freeze({
  SYSTEM: 1,
  CUSTOMER: 2,
  ACCOUNT: 3,
  GROUP: 4,
  USER: 5,
});

export const MailMergeObjectTypes = {
  TABLE: 1,
};

export function getFormatTypes() {
  return [
    { id: FormatTypes.GRID, displayValue: "Tabular" },
    { id: FormatTypes.PIVOT, displayValue: "Graph" },
    { id: FormatTypes.MAP, displayValue: "Map" },
  ];
}

export function getOwnerTypes() {
  return [
    { id: OwnerTypes.SYSTEM, displayValue: "System" },
    { id: OwnerTypes.CUSTOMER, displayValue: "Customer" },
    { id: OwnerTypes.ACCOUNT, displayValue: "Account" },
    { id: OwnerTypes.GROUP, displayValue: "Group" },
    { id: OwnerTypes.USER, displayValue: "User" },
  ];
}

export function getMailMergeObjectTypes() {
  return [{ id: MailMergeObjectTypes.TABLE, displayValue: "Table" }];
}

// Merge into datafield
export function getDataFieldFromName(fieldName) {
  const state = store.getState();

  return state.dataField.dataFields.find((dataField) => dataField.name === fieldName);
}

export function createDrillFilter(fieldName, value) {
  if (!value) {
    return { fieldName, operator: "isempty" };
  } else {
    return { fieldName, operator: "=", valueArray: [value] };
  }
}

export function getDataSource(dataSourceName) {
  const state = store.getState();
  const dataSources = state.lookup.global.find((lookup) => lookup.name === "DataSources");

  return dataSources.lookup.find((dataSource) => dataSource.name === dataSourceName);
}

function rootDataSource(dataSources, dataSourceName) {
  const dataSource = dataSources.lookup.find((dataSource) => dataSource.name === dataSourceName);

  if (!dataSource) {
    return null;
  } else if (dataSource.parent === null) {
    return dataSource.name;
  } else {
    return rootDataSource(dataSources, dataSource.parent);
  }
}

function rootSubqueryDataSource(dataSources, dataSourceName) {
  // Has to be a subquery present in the joins
  let zeroToManyParentRel = false;

  function rootSubqueryDataSourceEx(dataSourceName) {
    const dataSource = dataSources.lookup.find((dataSource) => dataSource.name === dataSourceName);

    if (!dataSource) {
      return null;
    }

    if (dataSource.parent === null) {
      return dataSource.name;
    }

    if (!zeroToManyParentRel) {
      zeroToManyParentRel = dataSource.zeroToManyParentRel;
    }

    return rootSubqueryDataSourceEx(dataSource.parent);
  }

  const rootDataSource = rootSubqueryDataSourceEx(dataSourceName);

  return zeroToManyParentRel ? rootDataSource : null;
}

export function subqueryParent(fieldName) {
  const state = store.getState();
  const dataSources = state.lookup.global.find((lookup) => lookup.name === "DataSources");

  const dataField = getDataFieldFromName(fieldName);
  if (!dataField) {
    return null;
  }

  const dataSource = dataSources.lookup.find((dataSource) => dataSource.name === dataField.dataSourceName);

  if (!dataSource.zeroToManyParentRel) {
    return null;
  }

  return dataSource;
}

export function getDisplayNameForField(fieldName, matterTypeIds) {
  if (fieldName === "contactLinks") return t("Contacts");
  if (fieldName === "countries") return t("Countries");
  const state = store.getState();
  try {
    let translationCode = "";
    if (matterTypeIds?.length === 1) {
      const dataFieldOverrideLookups = state.lookup.global.find((lookup) => lookup.name === "DataFieldOverrides");
      const overrideTranslation = dataFieldOverrideLookups.lookup.find(
        (lookup) => lookup.matterTypeId === matterTypeIds[0] && lookup.dataFieldName === fieldName
      );
      if (overrideTranslation) {
        translationCode = overrideTranslation.translationCode;
      }
    }
    if (!translationCode) {
      const dataField = getDataFieldFromName(fieldName);
      translationCode = dataField.translationCode;
    }
    return state.locale.translations[translationCode];
  } catch {
    return fieldName;
  }
}

export function getSectionChildDataSources(parentDataSource = "Matter") {
  const state = store.getState();
  const dataSources = state.lookup.global.find((lookup) => lookup.name === "DataSources");

  return dataSources.lookup.filter(
    (dataSource) => dataSource.zeroToManyParentRel && dataSource.parent === parentDataSource
  );
}

export function getOutputDataFields(queryTypeId, formatTypeId, matterTypeIds) {
  const state = store.getState();
  const queryTypes = state.lookup.global.find((lookup) => lookup.name === "QueryTypes");
  const dataSources = state.lookup.global.find((lookup) => lookup.name === "DataSources");
  const dataFields = state.dataField.dataFields;
  const excludedDataFields = matterTypeIds?.length > 0 ? getFieldsExcludedByMatterTypes(matterTypeIds) : [];
  const queryType = queryTypes.lookup.find((lookup) => lookup.id === queryTypeId);

  return dataFields.filter((dataField) => {
    if (
      dataField.dataSourceName === "CustomerImage" ||
      dataField.dataSourceName === "Reporting" ||
      dataField.dataSourceName === "System"
    ) {
      return !dataField.noOutput;
    }

    if (dataField.noOutput || rootDataSource(dataSources, dataField.dataSourceName) !== queryType.dataSourceName) {
      return false;
    }

    if (excludedDataFields.some((ef) => ef.dataFieldName === dataField.name)) {
      return false;
    }

    return true;
  });
}

export function getSortDataFields(queryTypeId) {
  const state = store.getState();
  const queryTypes = state.lookup.global.find((lookup) => lookup.name === "QueryTypes");
  const dataSources = state.lookup.global.find((lookup) => lookup.name === "DataSources");
  const dataFields = state.dataField.dataFields;

  const queryType = queryTypes.lookup.find((lookup) => lookup.id === queryTypeId);

  return dataFields.filter((dataField) => {
    if (dataField.dataSourceName === "Reporting" || dataField.dataSourceName === "System") {
      return !dataField.noSort;
    } else if (dataField.noSort) {
      return false;
    } else if (dataField.dataSourceName === queryType.dataSourceName) {
      return true;
    } else if (dataField.dataSourceName === queryType.secondaryDataSourceName) {
      return true;
    } else if (rootSubqueryDataSource(dataSources, dataField.dataSourceName) === queryType.dataSourceName) {
      // rootDataSource;
      return true;
    }

    return false;
  });
}

export function getOutputDataFieldGroups(queryTypeId, formatTypeId, matterTypeIds) {
  const state = store.getState();
  const dataFieldGroups = state.lookup.global.find((lookup) => lookup.name === "DataFieldGroups");
  const queryTypes = state.lookup.global.find((lookup) => lookup.name === "QueryTypes");
  const queryType = queryTypes.lookup.find((lookup) => lookup.id === queryTypeId);

  const outputDataFields = getOutputDataFields(queryTypeId, formatTypeId, matterTypeIds);

  return dataFieldGroups.lookup
    .filter((dataFieldGroup) => {
      return outputDataFields.some((dataField) => dataField.dataFieldGroupName === dataFieldGroup.name);
    })
    .map((dataFieldGroup) => {
      return {
        name: dataFieldGroup.name,
        label: getTranslation(dataFieldGroup.translationCode),
        dataFields: outputDataFields
          .filter((dataField) => dataField.dataFieldGroupName === dataFieldGroup.name)
          .map((dataField) => {
            return {
              name: dataField.name,
              displayName: getDisplayNameForField(dataField.name, matterTypeIds),
              sortIndex: dataField.dataFieldGroupSortIndex,
            };
          })
          .sort(compareByFieldWeighting),
      };
    })
    .sort((a, b) => {
      if (a.name === queryType.primaryDataFieldGroupName) {
        return -1;
      } else if (b.name === queryType.primaryDataFieldGroupName) {
        return 1;
      } else {
        return a.sortIndex < b.sortIndex ? -1 : 1;
      }
    });
}

export function getSectionTypeTitle(sectionTypeId) {
  const state = store.getState();
  const lookupSource = state.lookup.global.find((lookup) => lookup.name === "FormatSectionTypes");
  const sectionType = lookupSource.lookup.find((lookup) => lookup.id === sectionTypeId) ?? "??";

  return getTranslation(sectionType.translationCode);
}

export function getDefaultStyleItem(style) {
  let styleItem = null;

  if (style?.styleItems) {
    styleItem = style.styleItems.find((styleItem) => styleItem.default === true);
  }

  return styleItem;
}

export function pushStyle(array, type, styleItem) {
  if (styleItem) {
    array.push({ type: type, styleItem: styleItem });
  }
}

export function createCellStyle(array, column, columnStyleItems, cellPosition) {
  let columnStyleMerged = false;
  let style = {};

  for (let i = array.length - 1; i >= 0; i--) {
    if (i === 0 && array[i].type === "table") {
      const colStyleItem = columnStyleItems.find((col) => col.column === column);
      if (colStyleItem?.styleItem) {
        style = setStyle(style, colStyleItem.styleItem, "column", cellPosition);
      }
      columnStyleMerged = true;
    }
    style = setStyle(style, array[i].styleItem, array[i].type, cellPosition);
  }

  if (!columnStyleMerged) {
    const colStyleItem = columnStyleItems.find((col) => col.column === column);
    if (colStyleItem?.styleItem) {
      style = setStyle(style, colStyleItem.styleItem, "column", cellPosition);
    }
  }

  return style;
}

export function setStyle(style, styleItem, type = "cell", cellPosition = null) {
  if (styleItem) {
    if (!style.backgroundColor && styleItem.backgroundColor) {
      style.backgroundColor = styleItem.backgroundColor;
    }

    if (!style.color && styleItem.textColor) {
      style.color = styleItem.textColor;
    }

    if (!style.fontFamily && styleItem.fontFamily) {
      const state = store.getState();
      const formatFonts = state.lookup.global.find((lookup) => lookup.name === "FormatFonts");

      let font = formatFonts.lookup.find((font) => font.name === styleItem.fontFamily);
      if (font) {
        style.fontFamily = font.cssFontFamily;
      } else {
        style.fontFamily = styleItem.fontFamily;
      }
    }

    if (!style.fontSize && styleItem.fontSize) {
      style.fontSize = styleItem.fontSize + "px";
    }

    if (!style.fontWeight && styleItem.fontWeight) {
      style.fontWeight = styleItem.fontWeight;
    }

    if (!style.fontStyle && styleItem.fontStyle) {
      style.fontStyle = styleItem.fontStyle;
    }

    if (!style.textAlign && styleItem.textAlign) {
      style.textAlign = styleItem.textAlign;
    }

    if (!style.textDecorationLine && styleItem.textDecoration) {
      style.textDecorationLine = styleItem.textDecoration;
    }

    if (!style.verticalAlign && styleItem.verticalAlign) {
      style.verticalAlign = styleItem.verticalAlign;
    }

    switch (type) {
      case "cell":
        setBorderStyle(style, styleItem.border, "border");
        setBorderStyle(style, styleItem.topBorder, "borderTop");
        setBorderStyle(style, styleItem.bottomBorder, "borderBottom");
        setBorderStyle(style, styleItem.leftBorder, "borderLeft");
        setBorderStyle(style, styleItem.rightBorder, "borderRight");
        break;
      case "column":
        if (cellPosition.gridFirstRow) {
          setBorderStyle(style, styleItem.border, "borderTop");
          setBorderStyle(style, styleItem.topBorder, "borderTop");
        } else {
          setBorderStyle(style, styleItem.innerHorizontalBorder, "borderTop");
        }
        if (cellPosition.gridLastRow) {
          setBorderStyle(style, styleItem.border, "borderBottom");
          setBorderStyle(style, styleItem.bottomBorder, "borderBottom");
        } else {
          setBorderStyle(style, styleItem.innerHorizontalBorder, "borderBottom");
        }
        setBorderStyle(style, styleItem.border, "borderLeft");
        setBorderStyle(style, styleItem.leftBorder, "borderLeft");
        setBorderStyle(style, styleItem.border, "borderRight");
        setBorderStyle(style, styleItem.topBorder, "borderRight");
        break;
      case "row":
        setBorderStyle(style, styleItem.border, "borderTop");
        setBorderStyle(style, styleItem.topBorder, "borderTop");
        setBorderStyle(style, styleItem.border, "borderBottom");
        setBorderStyle(style, styleItem.bottomBorder, "borderBottom");
        if (cellPosition.firstColumn) {
          setBorderStyle(style, styleItem.border, "borderLeft");
          setBorderStyle(style, styleItem.leftBorder, "borderLeft");
        } else {
          setBorderStyle(style, styleItem.innerVerticalBorder, "borderLeft");
        }
        if (cellPosition.lastColumn) {
          setBorderStyle(style, styleItem.border, "borderRight");
          setBorderStyle(style, styleItem.topBorder, "borderRight");
        } else {
          setBorderStyle(style, styleItem.innerVerticalBorder, "borderRight");
        }
        break;
      case "section":
        if (cellPosition.sectionFirstRow) {
          setBorderStyle(style, styleItem.border, "borderTop");
          setBorderStyle(style, styleItem.topBorder, "borderTop");
        } else {
          setBorderStyle(style, styleItem.innerHorizontalBorder, "borderTop");
        }
        if (cellPosition.sectionLastRow) {
          setBorderStyle(style, styleItem.border, "borderBottom");
          setBorderStyle(style, styleItem.bottomBorder, "borderBottom");
        } else {
          setBorderStyle(style, styleItem.innerHorizontalBorder, "borderBottom");
        }
        if (cellPosition.firstColumn) {
          setBorderStyle(style, styleItem.border, "borderLeft");
          setBorderStyle(style, styleItem.leftBorder, "borderLeft");
        } else {
          setBorderStyle(style, styleItem.innerVerticalBorder, "borderLeft");
        }
        if (cellPosition.lastColumn) {
          setBorderStyle(style, styleItem.border, "borderRight");
          setBorderStyle(style, styleItem.topBorder, "borderRight");
        } else {
          setBorderStyle(style, styleItem.innerVerticalBorder, "borderRight");
        }
        break;
      case "table":
        if (cellPosition.gridFirstRow) {
          setBorderStyle(style, styleItem.border, "borderTop");
          setBorderStyle(style, styleItem.topBorder, "borderTop");
        } else {
          setBorderStyle(style, styleItem.innerHorizontalBorder, "borderTop");
        }
        if (cellPosition.gridLastRow) {
          setBorderStyle(style, styleItem.border, "borderBottom");
          setBorderStyle(style, styleItem.bottomBorder, "borderBottom");
        } else {
          setBorderStyle(style, styleItem.innerHorizontalBorder, "borderBottom");
        }
        if (cellPosition.firstColumn) {
          setBorderStyle(style, styleItem.border, "borderLeft");
          setBorderStyle(style, styleItem.leftBorder, "borderLeft");
        } else {
          setBorderStyle(style, styleItem.innerVerticalBorder, "borderLeft");
        }
        if (cellPosition.lastColumn) {
          setBorderStyle(style, styleItem.border, "borderRight");
          setBorderStyle(style, styleItem.topBorder, "borderRight");
        } else {
          setBorderStyle(style, styleItem.innerVerticalBorder, "borderRight");
        }
        break;
      default:
    }
  }

  return style;
}

export function highlightSelected(backgroundColor) {
  let newBackgroundColor = null;

  if (/^#[0-9A-F]{6}$/i.test(backgroundColor)) {
    const redHex = backgroundColor.substring(1, 3);
    const greenHex = backgroundColor.substring(3, 5);
    const blueHex = backgroundColor.substring(5, 7);

    const red = parseInt(redHex, 16);
    const green = parseInt(greenHex, 16);
    const blue = parseInt(blueHex, 16);

    const newHexRed = Math.max(0, red - 30).toString(16);
    const newHexGreen = Math.max(0, green - 30).toString(16);
    const newHexBlue = Math.max(0, blue - 30).toString(16);

    newBackgroundColor = "#" + newHexRed.padStart(2, 0) + newHexGreen.padStart(2, 0) + newHexBlue.padStart(2, 0);
  }

  return newBackgroundColor;
}

export function isGridSelected(selected) {
  return !!selected.find((item) => item.type === "grid");
}

export function isColumnSelected(selected, column, checkSpan = false) {
  if (checkSpan) {
    for (let i = 0; i < selected.length; i++) {
      const item = selected[i];

      if (item.type !== "column") {
        continue;
      }

      if (!item.spanCol) {
        if (item.col === column) {
          return true;
        }
      } else {
        const startCol = Math.min(item.col, item.spanCol);
        const endCol = Math.max(item.col, item.spanCol);
        if (column < startCol || column > endCol) {
          continue;
        }
        return true;
      }
    }
    return false;
  } else {
    return !!selected.find((item) => item.type === "column" && item.col === column);
  }
}

export function isSectionSelected(selected, sectionId) {
  return !!selected.find((item) => item.type === "section" && item.sectionId === sectionId);
}

export function isAnySectionCellSelected(selected, column, checkSpan = false) {
  if (checkSpan) {
    for (let i = 0; i < selected.length; i++) {
      const item = selected[i];

      if (item.type !== "cell") {
        continue;
      }

      if (!item.spanCol) {
        if (item.col === column) {
          return true;
        }
      } else {
        const startCol = Math.min(item.col, item.spanCol);
        const endCol = Math.max(item.col, item.spanCol);
        if (column < startCol || column > endCol) {
          continue;
        }
        return true;
      }
    }
    return false;
  } else {
    return !!selected.find((item) => item.type === "cell" && item.col === column);
  }
}

export function isRowSelected(selected, sectionId, row, checkSpan = false) {
  if (checkSpan) {
    for (let i = 0; i < selected.length; i++) {
      const item = selected[i];

      if (item.type !== "row" || item.sectionId !== sectionId) {
        continue;
      }

      if (!item.spanRow) {
        if (item.row === row) {
          return true;
        }
      } else {
        const startRow = Math.min(item.row, item.spanRow);
        const endRow = Math.max(item.row, item.spanRow);
        if (row < startRow || row > endRow) {
          continue;
        }
        return true;
      }
    }
    return false;
  } else {
    return !!selected.find((item) => item.type === "row" && item.sectionId === sectionId && item.row === row);
  }
}

export function isAnyRowCellSelected(selected, sectionId, row, checkSpan = false) {
  if (checkSpan) {
    for (let i = 0; i < selected.length; i++) {
      const item = selected[i];

      if (item.type !== "cell" || item.sectionId !== sectionId) {
        continue;
      }

      if (!item.spanRow) {
        if (item.row === row) {
          return true;
        }
      } else {
        const startRow = Math.min(item.row, item.spanRow);
        const endRow = Math.max(item.row, item.spanRow);
        if (row < startRow || row > endRow) {
          continue;
        }
        return true;
      }
    }
    return false;
  } else {
    return !!selected.find((item) => item.type === "cell" && item.sectionId === sectionId && item.row === row);
  }
}

export function isCellSelected(selected, sectionId, row, column, checkSpan = false) {
  if (checkSpan) {
    for (let i = 0; i < selected.length; i++) {
      const item = selected[i];

      if (item.type !== "cell" || item.sectionId !== sectionId) {
        continue;
      }

      if (!item.spanRow || !item.spanCol) {
        if (item.row === row && item.col === column) {
          return true;
        }
      } else {
        const startRow = Math.min(item.row, item.spanRow);
        const endRow = Math.max(item.row, item.spanRow);
        if (row < startRow || row > endRow) {
          continue;
        }
        const startCol = Math.min(item.col, item.spanCol);
        const endCol = Math.max(item.col, item.spanCol);
        if (column < startCol || column > endCol) {
          continue;
        }
        return true;
      }
    }
    return false;
  } else {
    return !!selected.find(
      (item) => item.type === "cell" && item.sectionId === sectionId && item.row === row && item.col === column
    );
  }
}

export function findSection(sectionGroup, sectionId) {
  let section = null;
  let sections = sectionGroup.sections;

  for (let i = 0; i < sections.length; i++) {
    if (sections[i].id === sectionId) {
      section = sections[i];
      break;
    }
    if (sections[i].sectionGroups) {
      for (let j = 0; j < sections[i].sectionGroups.length; j++) {
        section = findSection(sections[i].sectionGroups[j], sectionId);
        if (section) {
          break;
        }
      }
    }
    if (section) {
      break;
    }
  }

  return section;
}

export function findSectionGroup(sectionGroup, sectionId) {
  let matchSectionGroup = null;
  let sections = sectionGroup.sections;

  for (let i = 0; i < sections.length; i++) {
    if (sections[i].id === sectionId) {
      matchSectionGroup = sectionGroup;
      break;
    }
    if (sections[i].sectionGroups) {
      for (let j = 0; j < sections[i].sectionGroups.length; j++) {
        matchSectionGroup = findSectionGroup(sections[i].sectionGroups[j], sectionId);
        if (matchSectionGroup) {
          break;
        }
      }
    }
    if (matchSectionGroup) {
      break;
    }
  }

  return matchSectionGroup;
}

export function findRelatedSectionByType(sectionGroup, sectionId, relatedSectionType) {
  let section = null;
  let sections = sectionGroup.sections;
  let found = false;
  let testRelatedSectionType = null;

  for (let i = 0; i < sections.length; i++) {
    if (sections[i].id === sectionId) {
      found = true;
    }
    if (sections[i].sectionTypeId === relatedSectionType) {
      testRelatedSectionType = sections[i];
    }
    if (found && testRelatedSectionType) {
      section = testRelatedSectionType;
      break;
    }

    if (!found && sections[i].sectionGroups) {
      for (let j = 0; j < sections[i].sectionGroups.length; j++) {
        section = findRelatedSectionByType(sections[i].sectionGroups[j], sectionId, relatedSectionType);
        if (section) {
          break;
        }
      }
    }
    if (section) {
      break;
    }
  }

  return section;
}

export function updateSection(sectionGroup, section) {
  let updated = false;

  function updateSectionEx(sectionGroup, section) {
    let sections = sectionGroup.sections;

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].id === section.id) {
        sections = sections.map((thisSection) => (thisSection.id === section.id ? section : thisSection));
        updated = true;
        break;
      }
      let updatedSubsection = 0;
      let updateSectionGroup;
      if (sections[i].sectionGroups) {
        for (let j = 0; j < sections[i].sectionGroups.length; j++) {
          updateSectionGroup = updateSectionEx(sections[i].sectionGroups[j], section);
          if (updated) {
            updatedSubsection = j;
            break;
          }
        }
      }
      if (updated) {
        const newFormatsectionGroups = [...sections[i].sectionGroups];
        newFormatsectionGroups[updatedSubsection] = {
          ...updateSectionGroup,
        };

        const newSection = {
          ...sections[i],
          sectionGroups: newFormatsectionGroups,
        };

        // eslint-disable-next-line no-loop-func
        sections = sections.map((section) => (section.id === sections[i].id ? newSection : section));
        break;
      }
    }

    if (updated) {
      sectionGroup = {
        ...sectionGroup,
        sections: sections,
      };
    }

    return sectionGroup;
  }

  return updateSectionEx(sectionGroup, section);
}

export function setSectionField(sectionGroup, targetSection, targetCell, dataField) {
  let cell = null;
  if (targetSection.cells) {
    cell = targetSection.cells.find((cell) => cell.row === targetCell.row && cell.column === targetCell.column);
  }

  let newCell = null;
  if (cell) {
    newCell = { ...cell };
  } else {
    newCell = { ...targetCell };
  }
  if (dataField.name) {
    newCell.fieldName = dataField.name;

    if (
      targetSection.sectionTypeId === SectionTypeEnum.REPORT_HEADER ||
      targetSection.sectionTypeId === SectionTypeEnum.PAGE_HEADER
    ) {
      if (
        dataField.dataSourceName !== "CustomerImage" &&
        dataField.dataSourceName !== "Reporting" &&
        dataField.dataSourceName !== "System"
      ) {
        newCell.displayDataFieldLabel = true;
      }
    }
  } else {
    delete newCell.displayDataFieldLabel;
    delete newCell.fieldName;
  }

  let cells;

  const section = findSection(sectionGroup, targetSection.id);

  if (cell) {
    // Update
    cells = section.cells.map((cell) => (cell.row === newCell.row && cell.column === newCell.column ? newCell : cell));
  } else {
    // Insert
    if (section.cells) {
      cells = [...section.cells, { ...newCell }];
    } else {
      cells = [{ ...newCell }];
    }
  }

  const newSection = { ...section, cells: cells };

  return updateSection(sectionGroup, newSection);
}

export function setDefaultStyleItem(style, newDefaultStyleItem) {
  let newFormatStyle = null;
  let newFormatStyleItems = null;

  if (style?.styleItems) {
    newFormatStyleItems = style.styleItems.map((styleItem) =>
      styleItem.default === true ? newDefaultStyleItem : styleItem
    );
  } else {
    newFormatStyleItems = [];
    newFormatStyleItems.push(newDefaultStyleItem);
  }

  if (style) {
    newFormatStyle = { ...style, styleItems: newFormatStyleItems };
  } else {
    newFormatStyle = { styleItems: newFormatStyleItems };
  }

  return newFormatStyle;
}

export function setFormatStyleProperty(object, name, value, resetValue) {
  let style = null;
  let newFormatStyle = null;
  let styleItem = null;
  let newStyleItem = null;

  if (object?.style) {
    style = object.style;
    styleItem = getDefaultStyleItem(style);
  }

  if (styleItem) {
    if (resetValue && styleItem[name] === value) {
      newStyleItem = {
        ...styleItem,
        [name]: resetValue,
      };
    } else {
      newStyleItem = { ...styleItem, [name]: value };
    }
  } else {
    newStyleItem = { default: true, [name]: value };
  }

  newFormatStyle = setDefaultStyleItem(style, newStyleItem);

  return newFormatStyle;
}

export function mergeStyleItems(mergedStyle, styleItem) {
  if (styleItem) {
    if (!mergedStyle.backgroundColor && styleItem.backgroundColor) {
      mergedStyle.backgroundColor = styleItem.backgroundColor;
    }

    if (!mergedStyle.color && styleItem.color) {
      mergedStyle.color = styleItem.color;
    }

    if (!mergedStyle.fontFamily && styleItem.fontFamily) {
      mergedStyle.fontFamily = styleItem.fontFamily;
    }

    if (!mergedStyle.fontSize && styleItem.fontSize) {
      mergedStyle.fontSize = styleItem.fontSize;
    }

    if (!mergedStyle.fontStyle && styleItem.fontStyle) {
      mergedStyle.fontStyle = styleItem.fontStyle;
    }

    if (!mergedStyle.fontWeight && styleItem.fontWeight) {
      mergedStyle.fontWeight = styleItem.fontWeight;
    }

    if (!mergedStyle.textAlign && styleItem.textAlign) {
      mergedStyle.textAlign = styleItem.textAlign;
    }

    if (!mergedStyle.textColor && styleItem.textColor) {
      mergedStyle.textColor = styleItem.textColor;
    }

    if (!mergedStyle.textDecoration && styleItem.textDecoration) {
      mergedStyle.textDecoration = styleItem.textDecoration;
    }

    if (!mergedStyle.verticalAlign && styleItem.verticalAlign) {
      mergedStyle.verticalAlign = styleItem.verticalAlign;
    }
  }
}

function validInsertDelete(selected) {
  let valid = false;

  if (selected.length > 0) {
    const type = selected[0].type;
    valid = selected.every((item) => item.type === type);
  }

  return valid;
}

export function validInsert(selected) {
  return validInsertDelete(selected);
}

export function validDelete(selected) {
  return validInsertDelete(selected);
}

export function getSelectedType(selected) {
  let type = "";

  if (selected.length > 0 && selected.every((item) => item.type === selected[0].type)) {
    type = selected[0].type;
  }

  return type;
}

// Rename to shiftCellsRight?
export function shiftCellsLeft(sectionGroup, startCol, endCol, insertColumns) {
  let newSectionGroup = { ...sectionGroup };

  newSectionGroup.sections = sectionGroup.sections.map((section) => {
    const newSection = { ...section };

    if (section.cells) {
      newSection.cells = section.cells.map((cell) => {
        let newCell = cell;
        if (cell.colSpan && startCol > cell.column && startCol <= cell.column + cell.colSpan - 1) {
          newCell = {
            ...newCell,
            colSpan: newCell.colSpan + insertColumns,
          };
        }

        if (cell.column >= startCol) {
          newCell = { ...newCell, column: newCell.column + insertColumns };
        }

        return newCell;
      });
    }

    if (section.sectionGroups) {
      newSection.sectionGroups = section.sectionGroups.map((subsection) => {
        const newFormatSectionGroup = shiftCellsLeft(subsection, startCol, endCol, insertColumns);

        return newFormatSectionGroup;
      });
    }

    return newSection;
  });

  return newSectionGroup;
}

export function deleteColumnCells(sectionGroup, startCol, endCol, deleteColumns) {
  let newSectionGroup = { ...sectionGroup };

  newSectionGroup.sections = sectionGroup.sections.map((section) => {
    const newSection = { ...section };

    if (newSection.cells) {
      newSection.cells = newSection.cells.filter((cell) => cell.column < startCol || cell.column > endCol);

      newSection.cells = newSection.cells.map((cell) => {
        let newCell = cell;

        if (newCell.column > endCol) {
          newCell = { ...newCell, column: newCell.column - deleteColumns };
        }

        if (newCell.colSpan && newCell.column + newCell.colSpan - 1 >= startCol && newCell.column <= endCol) {
          let maxColSpanReduce = newCell.colSpan - Math.max(startCol - newCell.column, 0);
          let reduceSpan = Math.min(deleteColumns, maxColSpanReduce);

          if (newCell.colSpan - reduceSpan <= 1) {
            newCell = { ...newCell };
            delete newCell.colSpan;
          } else {
            newCell = {
              ...newCell,
              colSpan: newCell.colSpan - reduceSpan,
            };
          }
        }

        return newCell;
      });
    }

    if (newSection.sectionGroups) {
      newSection.sectionGroups = newSection.sectionGroups.map((subsection) => {
        const newFormatSectionGroup = deleteColumnCells(subsection, startCol, endCol, deleteColumns);

        const newSubsection = {
          ...subsection,
          sectionGroup: newFormatSectionGroup,
        };

        return newSubsection;
      });
    }

    return newSection;
  });

  return newSectionGroup;
}

export function deleteSection(sectionGroup, sectionId) {
  let deleted = false;

  function deleteSectionEx(sectionGroup, sectionId) {
    let updateSectionGroup = sectionGroup;

    for (let i = 0; i < sectionGroup.sections.length; i++) {
      const section = sectionGroup.sections[i];

      if (section.id === sectionId) {
        updateSectionGroup = { ...sectionGroup };
        updateSectionGroup.sections = filterOutOnId(sectionGroup.sections, sectionId);
        deleted = true;
        break;
      }

      if (section.sectionGroups) {
        for (let j = 0; j < section.sectionGroups.length; j++) {
          const subSection = section.sectionGroups[j];
          const newSectionGroup = deleteSectionEx(subSection, sectionId);
          if (deleted) {
            const newSection = { ...section };
            if (newSectionGroup.sections.length === 0) {
              if (section.sectionGroups.length === 1) {
                // Remove sectionGroups
                delete newSection.sectionGroups;
              } else {
                // Remove subsection
                newSection.sectionGroups = [...section.sectionGroups];
                newSection.sectionGroups.splice(j, 1);
              }
            } else {
              // Update sectionGroups
              const newSubSection = newSectionGroup;
              newSection.sectionGroups = [...section.sectionGroups];
              newSection.sectionGroups[j] = newSubSection;
            }
            updateSectionGroup = { ...sectionGroup };
            updateSectionGroup.sections = sectionGroup.sections.map((section) =>
              section.id === newSection.id ? newSection : section
            );
            break;
          }
        }
      }
    }

    return updateSectionGroup;
  }

  return deleteSectionEx(sectionGroup, sectionId);
}

export function removeUnusedSectionCells(sectionGroup, maxCol) {
  let updatedSectionGroup = null;
  let updatedFormatSections = null;

  for (let i = 0; i < sectionGroup.sections.length; i++) {
    let section = sectionGroup.sections[i];
    let updatedSection = null;
    if (section.cells) {
      for (let j = 0; j < section.cells.length; j++) {
        let cell = section.cells[j];
        if (cell.column <= maxCol) {
          continue;
        }
        let cells = section.cells.filter((cell) => cell.column <= maxCol);
        updatedSection = { ...section, cells: cells };
        break;
      }
      if (updatedSection) {
        if (!updatedFormatSections) {
          updatedFormatSections = [...sectionGroup.sections];
        }
        updatedFormatSections = updatedFormatSections.map((section) =>
          section.id === updatedSection.id ? updatedSection : section
        );
      }
    }
  }

  if (updatedFormatSections) {
    updatedSectionGroup = {
      ...sectionGroup,
      sections: updatedFormatSections,
    };
  }

  return updatedSectionGroup ? updatedSectionGroup : sectionGroup;
}

export function buildPivotAxis(axisArray, pivotData) {
  let pivotAxis = [];

  if (axisArray.length === 0) {
    return pivotAxis;
  }

  // Get the datafields for the axis columns
  const keyDataFields = axisArray.map((axis) => getDataFieldFromName(axis.keyDataFieldName));
  const labelDataFields = axisArray.map((axis) =>
    axis.labelDataFieldName ? getDataFieldFromName(axis.labelDataFieldName) : null
  );

  // Consolidate axis key data as not necessarily unique on an axis
  for (let i = 0; i < pivotData.length; i++) {
    const pivotRow = pivotData[i];
    const keys = [axisArray.length];
    const labels = [axisArray.length];
    const keyColumns = [axisArray.length];
    let compositeKey = null;
    for (let x = 0; x < axisArray.length; x++) {
      const axisColumn = axisArray[x];
      compositeKey += pivotRow.keys[axisColumn.keyColumn] + "|";
      keys[x] = pivotRow.keys[axisColumn.keyColumn];
      keyColumns[x] = axisColumn.keyColumn;
      if (labelDataFields[x] != null) {
        labels[x] = pivotRow.labels[axisColumn.labelColumn];
      } else if (keyDataFields[x].lookupDataSourceName) {
        if (!keys[x]) {
          labels[x] = "No " + getDisplayNameForField(axisColumn.keyDataFieldName);
        } else {
          labels[x] = getLookupValueForField(axisColumn.keyDataFieldName, keys[x]);
        }
      } else {
        labels[x] = keys[x];
      }
    }

    if (!pivotAxis.find((el) => el.compositeKey === compositeKey)) {
      pivotAxis.push({
        compositeKey,
        keyColumns,
        keys,
        labels,
      });
    }
  }

  pivotAxis.sort((a, b) => a.labels.join("").localeCompare(b.labels.join("")));

  // Calculate the spans
  const prevKeys = new Array(axisArray.length);
  const startSpan = new Array(axisArray.length);
  const span = new Array(pivotAxis.length);
  const inSpan = new Array(pivotAxis.length);
  for (let i = 0; i < pivotAxis.length; i++) {
    span[i] = new Array(axisArray.length);
    inSpan[i] = new Array(axisArray.length).fill(false);
  }
  for (let i = 0; i < pivotAxis.length; i++) {
    const trgElement = pivotAxis[i];

    for (let j = 0; j < axisArray.length; j++) {
      if (i === 0 || trgElement.keys[j] !== prevKeys[j]) {
        for (let k = j; k < axisArray.length; k++) {
          if (i !== 0) {
            span[startSpan[k]][k] = i - startSpan[k];
          }
          startSpan[k] = i;
          prevKeys[k] = trgElement.keys[k];
        }
        break;
      } else {
        inSpan[i][j] = true;
      }
    }
  }

  // Close off any open groups
  for (let i = 0; i < axisArray.length; i++) {
    span[startSpan[i]][i] = pivotAxis.length - startSpan[i];
  }

  // Assign span info back to the target array
  pivotAxis = pivotAxis.map((element, index) => {
    return { ...element, span: span[index], inSpan: inSpan[index] };
  });

  return pivotAxis;
}
