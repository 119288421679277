import { createNewUserInCustomer, updateUserDetails } from "api/security";
import Checkbox from "components/global/Checkbox";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { labelTypes, userStatusTypes } from "utilities/constants";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { findById, idsAreEqual } from "utilities/stringAndArray";
import SecurityColumnHeader from "./SecurityColumnHeader";

export default function Users() {
  const securityState = useSelector((state) => state.security);
  const customerState = useSelector((state) => state.customer);
  const { customerId } = useParams();

  const [users, setUsers] = useState([]);
  const [addUserInputValues, setAddUserInputValues] = useState({ name: "", email: "" });
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);
  const [showHiddenUsers, setShowHiddenUsers] = useState(false);
  const [sortParams, setSortParams] = useState({
    sortCol: "name",
    sortAsc: true,
  });

  useEffect(() => {
    setUsers([...securityState.currentCustomer.users]);
  }, [securityState.currentCustomer.users]);

  const customerStatuses = getAllLookupValuesForSource("CustomerStatuses");

  const handleAddingNewUser = () => {
    createNewUserInCustomer(securityState.currentCustomer.id, {
      id: addUserInputValues.email,
      fullName: addUserInputValues.name,
      phoneNumber: null,
      timeZone: null,
      isAdminUser: false,
      isAppUser: true,
    });

    setIsAddingNewUser(false);
    setAddUserInputValues({ name: "", email: "" });
  };

  const handleUpdateSortParams = (sortedData, newSortParams) => {
    setUsers(sortedData);
    setSortParams(newSortParams);
  };

  const toggleUserCheckbox = (fieldName, user) => {
    if (!user.isAdminUser && fieldName === "isAppUser") return;
    if (!user.isAppUser && fieldName === "isAdminUser") {
      updateUserDetails(customerId, user.id, "isAppUser", user.isAppUser, true);
    }
    updateUserDetails(customerId, user.id, fieldName, user[fieldName], !user[fieldName]);
  };

  const renderUserTableBody =
    users?.length > 0 &&
    users.map((user) => {
      const selectedCustomerStatus = findById(customerStatuses, user.customerUserStatusId);
      if (
        (!showHiddenUsers &&
          !idsAreEqual(user.customerUserStatusId, userStatusTypes.ACTIVE) &&
          !idsAreEqual(user.customerUserStatusId, userStatusTypes.PENDING)) ||
        !selectedCustomerStatus
      )
        return null;
      const linkedAccountCountClassNameBase = "security__table__body-row-cell";
      let linkedAccountCountClassName = linkedAccountCountClassNameBase;
      if (user.linkedAccountsCount === 0)
        linkedAccountCountClassName = `${linkedAccountCountClassNameBase} ${linkedAccountCountClassNameBase}--red color-text-red`;
      return (
        <tr className="security__table__body-row" key={user.id}>
          <td className="security__table__body-row-cell">
            <Link to={`/customers/${securityState.currentCustomer.id}/users/${user.id}`}>{user.fullName}</Link>
          </td>
          <td className="security__table__body-row-cell">{user.email}</td>
          <td className="security__table__body-row-cell">
            <Checkbox
              position="center"
              id={user.userId}
              onCheckChange={() => toggleUserCheckbox("isAdminUser", user)}
              isChecked={user.isAdminUser}
            />
          </td>
          <td className="security__table__body-row-cell">
            <Checkbox
              position="center"
              id={user.userId}
              onCheckChange={() => toggleUserCheckbox("isAppUser", user)}
              isChecked={user.isAppUser}
              isDisabled={!user.isAdminUser}
            />
          </td>
          {customerState.fullName === user.fullNames ? null : (
            <td className="security__table__body-row-cell">
              <SuperSelect
                id={user.userId}
                selectedOptionId={selectedCustomerStatus.id}
                options={customerStatuses}
                onChange={(value) =>
                  updateUserDetails(customerId, user.id, "customerUserStatusId", user.customerUserStatusId, value)
                }
              />
            </td>
          )}
          <td className={linkedAccountCountClassName}>{user.linkedAccountsCount}</td>
        </tr>
      );
    });

  const renderUsersTable = (
    <>
      <div className="security__title-container">
        <div className="security__section-title">{t("Users")}</div>
        <div className="security__show-hidden-users link" onClick={() => setShowHiddenUsers(!showHiddenUsers)}>
          {t(`${showHiddenUsers ? "Hide " : "Show "}Hidden Users`)}
        </div>
      </div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              <SecurityColumnHeader
                fieldName="fullName"
                fieldType={labelTypes.STRING}
                displayName="Name"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="email"
                fieldType={labelTypes.STRING}
                displayName="Email"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="isAdminUser"
                fieldType={labelTypes.BOOLEAN}
                displayName="Admin User"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="isAppUser"
                fieldType={labelTypes.BOOLEAN}
                displayName="App User"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="customerUserStatusId"
                fieldType={labelTypes.LOOKUP}
                displayName="Status"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="linkedAccountsCount"
                fieldType={labelTypes.DECIMAL}
                displayName="Linked Accounts"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
            </tr>
          </thead>
          <tbody className="security__table__body">
            {renderUserTableBody}
            {isAddingNewUser && (
              <tr className="security__table__body-row">
                <td className="security__table__body-row-cell">
                  <input
                    placeholder="Name"
                    id="addUserName"
                    type="text"
                    value={addUserInputValues.givenName}
                    onChange={(e) => setAddUserInputValues({ ...addUserInputValues, name: e.target.value })}
                  ></input>
                </td>
                <td className="security__table__body-row-cell">
                  <input
                    placeholder="Email"
                    id="addUserEmail"
                    type="text"
                    value={addUserInputValues.email}
                    onChange={(e) => setAddUserInputValues({ ...addUserInputValues, email: e.target.value })}
                  ></input>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {!isAddingNewUser ? (
        <button className="flex-row-center" onClick={() => setIsAddingNewUser(true)}>
          {t("Add User")}
        </button>
      ) : (
        <button onClick={() => handleAddingNewUser()}>{t("Confirm")}</button>
      )}
    </>
  );

  //MAIN RENDER
  return <>{renderUsersTable}</>;
}
