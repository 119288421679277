import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addMailMergeObject } from "redux/reportSlice";
import Modal from "components/global/Modal";
import { getSectionChildDataSources, getMailMergeObjectTypes } from "utilities/reportEditor";
import { getTranslation } from "utilities/reportShared";
import { compareByDisplayValueAsc } from "utilities/stringAndArray";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";

function CreateObject() {
  const dispatch = useDispatch();

  const [objectTypes] = useState(getMailMergeObjectTypes());

  const handleAddObject = () => {
    dispatch(
      addMailMergeObject({
        mailMergeFormatObjectType: selectedObjectType,
        dataSourceName: selectedDataSource,
        name: objectName,
      })
    );
    setSelectedObjectType(1);
    setSelectedDataSource(null);
    setObjectName(null);
  };

  const [selectedObjectType, setSelectedObjectType] = useState(1);

  const [selectedDataSource, setSelectedDataSource] = useState(null);

  const [objectName, setObjectName] = useState(null);

  const [availableDataSources, setAvailableDataSources] = useState([]);

  useEffect(() => {
    const childDataSources = getSectionChildDataSources("Matter");

    setAvailableDataSources(
      childDataSources
        .map((dataSource) => {
          return { id: dataSource.name, displayValue: getTranslation(dataSource.translationCode) };
        })
        .sort(compareByDisplayValueAsc)
    );
  }, []);

  const handleObjectTypeChange = (objectTypeId) => {
    setSelectedObjectType(objectTypeId);
  };

  const handleDataSourceChange = (dataSourceId) => {
    setSelectedDataSource(dataSourceId);
  };

  return (
    <Modal buttonLabel="New Object" title="New Object" closeOnly={false} onConfirm={handleAddObject}>
      <div style={{ marginBottom: 10 }}>
        <div style={{ marginBottom: 5 }}>{t("Object type:")}</div>
        <div>
          <SuperSelect
            options={objectTypes}
            selectedOptionId={selectedObjectType}
            isMultiValued={false}
            onChange={handleObjectTypeChange}
            placeholderText="Select an object type"
          />
        </div>
      </div>
      <div style={{ marginBottom: 10 }}>
        <div style={{ marginBottom: 5 }}>{t("Data source:")}</div>
        <SuperSelect
          options={availableDataSources}
          selectedOptionId={selectedDataSource}
          isMultiValued={false}
          onChange={handleDataSourceChange}
          placeholderText="Select a data source"
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <div style={{ marginBottom: 5 }}>{t("Name:")}</div>
        <div>
          <input type="text" value={objectName} onChange={(e) => setObjectName(e.target.value)} />
        </div>
      </div>
    </Modal>
  );
}

export default CreateObject;
