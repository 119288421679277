import Switch from "components/global/Switch";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterOptions,
  setChildTableIsExpandingAll,
  setShowExtraFieldsGoods,
  setShowExtraFieldsDocuments,
  setShowAssignedDate,
} from "redux/matterSlice";
import Panel from "./Panel";
import PropTypes from "prop-types";
import { matterTypes, sectionTypes } from "utilities/constants";
import FileUploader from "components/global/FileUploader";
import { getAllListTypes, getAllReferenceTypes } from "utilities/lookup";
import { defaultSectionOrder, sectionDefinitions } from "utilities/sectionDefinitions";
import { t } from "locale/dictionary";
import { findById, hasMatchingId, idsAreEqual } from "utilities/stringAndArray";

// Container Component for the list of data panels for a matter record
function PanelList({ record, onSetConnectedMatterPopupVisible }) {
  const matterState = useSelector((state) => state.matter);
  const filterOptions = matterState.filterOptions;

  const dispatch = useDispatch();

  const [alerted, setAlerted] = useState(false);

  const matterTypeId = record.matter_MatterTypeId;

  //TODO: Get user order of panels in API call here instead of example below
  const userSectionOrder = null; //[{ matterTypeId: 60, sectionOrder: [1, 110, 200, 20, 30, 40, 50, 60, 70, 80, 90, 100] }];

  const [isFilterStatusOnActive, setIsFilterStatusOnActive] = useState(false);

  const onActionsFilterActiveToggle = () => {
    let fos = [...filterOptions];
    if (isFilterStatusOnActive) {
      fos = fos.filter((filterOption) => filterOption.fieldName !== "matterAction_CompletedDate");
    } else {
      fos.push({ fieldName: "matterAction_CompletedDate", valueToEqual: null });
    }
    dispatch(setFilterOptions(fos));

    setIsFilterStatusOnActive(!isFilterStatusOnActive);
  };

  // If there is data in the "first use" fields of the goods table, default to show these columns
  useEffect(() => {
    const goodsHaveData = record?.goods?.some(
      (good) => good.matterGoods_FirstUseDate?.length > 0 || good.matterGoods_FirstUseInCommerceDate?.length > 0
    );
    dispatch(setShowExtraFieldsGoods(goodsHaveData));
  }, [record?.goods]);

  // Hide email columns in documents unless documents list contains email data
  useEffect(() => {
    const documentsHaveEmailData = record?.documents?.some((document) => document.matterDocument_EmailFrom?.length > 0);
    dispatch(setShowExtraFieldsDocuments(documentsHaveEmailData));
  }, [record?.documents]);

  const onChildTableExpandAllChange = (sectionId) => {
    dispatch(setChildTableIsExpandingAll(sectionId));
  };

  const syncAllDataPanelListScrolls = (e) => {
    const elements = document.getElementsByClassName("data-panel-list__outer");
    for (let n = 0; n < elements.length; n++) {
      elements[n].scrollTop = e.target.scrollTop;
    }
  };

  // Determine reference types to display in ADDITIONAL DETAILS section
  // TODO (FUTURE): Add custom fields here also
  const referenceTypesAll = getAllReferenceTypes();

  // Remove any that are already hardcoded into existing sections
  let existingReferenceTypeFields = [];
  sectionDefinitions.forEach((sectionDefinition) => {
    const thisSectionReferenceTypeFields = sectionDefinition.fieldList.filter((field) =>
      field.fieldName.startsWith("REF")
    );
    if (thisSectionReferenceTypeFields.length > 0) {
      existingReferenceTypeFields = [...existingReferenceTypeFields, ...thisSectionReferenceTypeFields];
    }
  });
  let referenceTypesRemaining = [];
  referenceTypesRemaining = referenceTypesAll.filter(
    (rta) => !existingReferenceTypeFields.some((existing) => idsAreEqual(existing.referenceTypeId, rta.id))
  );

  const additionalDetailsFieldList = referenceTypesRemaining.map((rtr) => {
    return { fieldName: `REF${rtr.id}`, width: 33, referenceTypeId: rtr.id };
  });
  const additionalDetailsSection = {
    id: 19,
    name: "Additional Details",
    type: sectionTypes.FORM,
    fieldList: additionalDetailsFieldList,
  };
  sectionDefinitions.push(additionalDetailsSection);

  // Determine list types to display in CATEGORIES section
  const listTypesAll = getAllListTypes();
  let listTypesForThisMatterType =
    listTypesAll &&
    listTypesAll.length > 0 &&
    listTypesAll.filter(
      (listType) => listType.matterTypeIds.length === 0 || hasMatchingId(listType.matterTypeIds, matterTypeId)
    );
  // Remove any that are already hardcoded into existing sections
  let existingListTypeFields = [];
  sectionDefinitions.forEach((sectionDefinition) => {
    const thisSectionListTypeFields = sectionDefinition.fieldList.filter((field) => field.isListType);
    if (thisSectionListTypeFields.length > 0) {
      existingListTypeFields = [...existingListTypeFields, ...thisSectionListTypeFields];
    }
  });
  let listTypesRemaining = [];
  listTypesRemaining = listTypesForThisMatterType.filter(
    (ltmt) => !existingListTypeFields.some((existing) => existing.fieldName === ltmt.code)
  );

  const categoriesFieldList = listTypesRemaining.map((ltr) => {
    return { fieldName: ltr.code, width: 33, isListType: true };
  });
  const categoriesSection = {
    id: 70,
    name: "Categories",
    type: sectionTypes.FORM,
    fieldList: categoriesFieldList,
  };
  sectionDefinitions.push(categoriesSection);

  // Fill the sections to be rendered list from the combination of section definitions and ordering arrays for this matter type
  // If no user section order defined for a particular matter type, then the default ordering will be used
  let sectionOrderForMatterType = userSectionOrder?.find((orderRow) =>
    idsAreEqual(orderRow.matterTypeId, matterTypeId)
  );
  if (!sectionOrderForMatterType)
    sectionOrderForMatterType = defaultSectionOrder.find((orderRow) =>
      idsAreEqual(orderRow.matterTypeId, matterTypeId)
    );
  if (!sectionOrderForMatterType && !alerted) {
    alert("A page definition has not yet been provided for this matter type");
    setAlerted(true);
  }
  const sectionsForMatterType = sectionOrderForMatterType?.sectionOrder.map((sectionId) =>
    findById(sectionDefinitions, sectionId)
  );

  return (
    <div className="data-panel-list__outer" onScroll={(e) => syncAllDataPanelListScrolls(e)}>
      {sectionsForMatterType?.map((section) => {
        if (!section) return;
        let sectionName = section.name;
        if (section.id === 62) {
          switch (matterTypeId) {
            case 10:
              sectionName = t("Authors");
              break;
            case 20:
              sectionName = t("Designers");
              break;
            case 40:
              sectionName = t("Inventors");
              break;
            default:
              break;
          }
        }
        let children = null;

        // Starting point for "keyProps" - this is a core set of info used by all controls in the hierarchy, down to the specific field control level
        const keyProps = {
          record,
          fieldList: section.fieldList,
          tableName: section.tableName,
          childTable: section.childTable,
          sectionName,
          sectionType: section.type,
          sectionId: section.id,
          hasSpecialActionBar: section.hasSpecialActionBar,
        };

        // Special hide/show column sliders for Goods panel
        if (section.id === 20) {
          children = (
            <Switch
              text="Show First use date"
              isOn={matterState?.showExtraFieldsGoods}
              onSwitchChange={() => dispatch(setShowExtraFieldsGoods(!matterState?.showExtraFieldsGoods))}
            />
          );
        }

        // Actions panel as special active/inactive switch control in header
        if (section.id === 30) {
          children = (
            <Switch
              text="Show Incomplete Only"
              isOn={isFilterStatusOnActive}
              onSwitchChange={onActionsFilterActiveToggle}
            />
          );
        }

        // Special slider to expand all contact child tables in Company Links
        if (section.id === 50 || section.id === 80) {
          children = (
            <Switch
              text="Expand All"
              isOn={matterState?.childTableIsExpandingAll.includes(section.id)}
              onSwitchChange={() => onChildTableExpandAllChange(section.id)}
            />
          );
        }

        //const isShowingEmailColumns = !matterState.hiddenFields.some((hf) => hf.includes("Email"));
        // Special file uploader dropzone for documents
        // Special hide/show email column sliders for documents panel
        if (section.id === 90) {
          children = (
            <>
              <FileUploader record={record} keyProps={keyProps} />
              <div className="action-bar__switch">
                <Switch
                  text="Show Email Columns"
                  isOn={matterState?.showExtraFieldsDocuments}
                  onSwitchChange={() => dispatch(setShowExtraFieldsDocuments(!matterState?.showExtraFieldsDocuments))}
                />
              </div>
            </>
          );
        }

        return (
          <Panel
            key={section.id}
            keyProps={keyProps}
            onSetConnectedMatterPopupVisible={onSetConnectedMatterPopupVisible}
          >
            {children}
          </Panel>
        );
      })}
    </div>
  );
}

PanelList.propTypes = {
  record: PropTypes.object,
  onSetConnectedMatterPopupVisible: PropTypes.func,
};

export default PanelList;
