import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  anyWordStartsWith,
  filterOutOnId,
  findById,
  getFieldCompareFn,
  hasMatchingId,
  showPartialMatchHighlight,
} from "utilities/stringAndArray";
import Checkbox from "components/global/Checkbox";
import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import { matterTypes } from "utilities/constants";
import { getValidMatterTypesForLookupValue } from "utilities/datafield";

function SuggestedFieldPopupLookup({
  field,
  parent,
  operator,
  valueArray,
  onOperatorChange,
  onValueChange,
  onIsApplicableChange,
}) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;

  const [fieldOptions, setFieldOptions] = useState([]);
  const [checkedOptions, setCheckedOptions] = useState(valueArray ? valueArray.map((co) => Number(co)) : []);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [displaySelected, setDisplaySelected] = useState(false);
  const [isAllOptionsSelected, setIsAllOptionsSelected] = useState(false);
  const [optionSearchText, setOptionSearchText] = useState("");

  // On any change to the user's input text, set sort order and highlighting of array of options
  useEffect(() => {
    let lookupDisplayList = field.lookupDisplayList ? [...field.lookupDisplayList] : null;

    if (field.lookupDisplayList) {
      let fieldOptionsMatching = [];
      let fieldOptionsNotMatching = [];

      // Special case for Linked Matter Types - we don't show Companies
      if (field.fieldName === "matterLink_MatterTypeId") {
        lookupDisplayList = filterOutOnId(lookupDisplayList, matterTypes.COMPANY);
      }

      const setFieldOptionsForInputItem = (inputItem) => {
        const inputItemLower = inputItem.toLowerCase();

        let fieldOptionsTemp = [];
        lookupDisplayList.forEach((item) => {
          let fieldOption = {
            id: item.id,
            displayValue: item.displayValue,
            optionalCodes: item.optionalCodes,
            highlightArray: showPartialMatchHighlight(item.displayValue, [inputItem]),
          };
          fieldOptionsTemp.push(fieldOption);
        });
        fieldOptionsMatching = [
          ...fieldOptionsMatching,
          ...fieldOptionsTemp.filter(
            (fo) =>
              ((fo.displayValue && anyWordStartsWith(fo.displayValue.toLowerCase(), inputItemLower)) ||
                (fo.optionalCodes && anyWordStartsWith(fo.optionalCodes.toLowerCase(), inputItemLower))) &&
              !hasMatchingId(fieldOptionsMatching, fo.id) // Don't add if already added
          ),
        ];
        fieldOptionsNotMatching = [
          ...fieldOptionsNotMatching,
          ...fieldOptionsTemp.filter(
            (fo) =>
              !(
                (fo.displayValue && anyWordStartsWith(fo.displayValue.toLowerCase(), inputItemLower)) ||
                (fo.optionalCodes && anyWordStartsWith(fo.optionalCodes.toLowerCase(), inputItemLower))
              ) && !hasMatchingId(fieldOptionsNotMatching, fo.id) // Don't add if already added
          ),
        ];
      };

      let inputArray = simpleSearch.inputArray;

      if (optionSearchText.length > 2) {
        const optionSearchArray = optionSearchText.split(/[\s,]+/);
        inputArray = [...inputArray, ...optionSearchArray];
      }

      if (inputArray.length === 0) {
        setFieldOptionsForInputItem("");
      } else {
        inputArray.forEach((inputItem) => {
          setFieldOptionsForInputItem(inputItem);
        });
        fieldOptionsMatching = fieldOptionsMatching.map((fom) => ({ ...fom, isMatched: true }));
      }

      fieldOptionsNotMatching = fieldOptionsNotMatching.filter((fonm) => !hasMatchingId(fieldOptionsMatching, fonm.id));

      const compareFn = getFieldCompareFn(field);

      const fieldOptionsSorted = [
        ...fieldOptionsMatching.toSorted(compareFn),
        ...fieldOptionsNotMatching.toSorted(compareFn),
      ];
      setFieldOptions(fieldOptionsSorted);
    }
  }, [
    simpleSearch.inputArray,
    simpleSearch.filters,
    simpleSearch.labelSelected,
    field.dataType,
    field.fieldName,
    field.lookupDisplayList,
    optionSearchText,
  ]);

  useEffect(() => {
    setIsAllOptionsSelected(checkedOptions && fieldOptions && fieldOptions.length === checkedOptions.length);
  }, [fieldOptions, checkedOptions]);

  useEffect(() => {
    let displayFieldOptionsTemp = [];
    if (!displaySelected) {
      displayFieldOptionsTemp = [...fieldOptions];
    } else {
      displayFieldOptionsTemp = fieldOptions.filter(
        (item) => item.isMatched === true || hasMatchingId(checkedOptions, item.id)
      );
    }
    setDisplayOptions(displayFieldOptionsTemp);
  }, [fieldOptions, checkedOptions, displaySelected]);

  const checkIsApplicable = (operatorLocal, checkedOptionsLocal) => {
    if (!checkedOptionsLocal) checkedOptionsLocal = checkedOptions;
    if (operatorLocal === "isempty" || operatorLocal === "isnotempty") {
      onIsApplicableChange(true);
    } else {
      onIsApplicableChange(checkedOptionsLocal.length > 0);
    }
  };

  const handleListItemClick = (id) => {
    let checkedOptionsTemp = [...checkedOptions];
    let index = checkedOptionsTemp.findIndex((co) => co === id);
    if (index >= 0) {
      checkedOptionsTemp.splice(index, 1);
    } else {
      const fieldOption = findById(fieldOptions, id);
      checkedOptionsTemp = [...checkedOptionsTemp, fieldOption.id];
    }
    setCheckedOptions(checkedOptionsTemp);
    checkIsApplicable(operator, checkedOptionsTemp);

    // Send update to top level for when the user clicks Apply it will save all checked items correctly
    onValueChange(checkedOptionsTemp);
  };

  const handleToggleAllOptionsSelected = () => {
    const checkedOptionsTemp = isAllOptionsSelected ? [] : fieldOptions.map((fo) => fo.id);
    setCheckedOptions(checkedOptionsTemp);
    setIsAllOptionsSelected(!isAllOptionsSelected);
    checkIsApplicable(operator, checkedOptionsTemp);
    onValueChange(checkedOptionsTemp);
  };

  const handleOperatorChange = (op) => {
    onOperatorChange(op);
    onIsApplicableChange(true);
  };

  const renderLookupOperators = (
    <div className="suggested-field-popup__operator">
      {/* <FaAngleDown /> */}
      <select value={operator} onChange={(e) => handleOperatorChange(e.target.value)} tabIndex={0}>
        <option value="=">Includes</option>
        <option value="<>">Excludes</option>
        {!simpleSearch.queryType?.isExternal && (
          <>
            <option value="isempty">Empty</option>
            <option value="isnotempty">Not Empty</option>
          </>
        )}
      </select>
    </div>
  );

  const renderLookupOptions = (options) => {
    let matterTypeIds = null;
    if (simpleSearch?.filters) {
      const matterTypeFilter = simpleSearch.filters.find((filter) => filter.fieldName === "matter_MatterTypeId");
      if (matterTypeFilter) {
        matterTypeIds = matterTypeFilter.valueArray;
      }
    }

    return options.map((item) => {
      const matterTypesDisplayValues =
        matterTypeIds?.length > 1 ? getValidMatterTypesForLookupValue(field.fieldName, item.id, matterTypeIds) : null;
      // Output matched fields
      const isChecked = checkedOptions.some((co) => co === item.id);
      return (
        <div className="suggested-field-popup__option-container" key={item.id}>
          <Checkbox isChecked={isChecked} onCheckChange={(e) => handleListItemClick(item.id)} />
          <div className="suggested-field-popup__option-texts">
            <div className="suggested-field-popup__option">
              {item.highlightArray[0]?.length === item.displayValue?.length ? (
                <span className="suggested-field-popup__option suggested-field-popup__option--no-match">
                  {item.displayValue}
                </span>
              ) : (
                <>
                  {item.highlightArray[0]}
                  {item.highlightArray[0].endsWith(" ") && <span>&nbsp;</span>}
                  <span className="suggested-field-popup__option suggested-field-popup__option--match">
                    {item.highlightArray[1]}
                  </span>
                  {item.highlightArray[2].startsWith(" ") && <span>&nbsp;</span>}
                  {item.highlightArray[2]}
                </>
              )}
            </div>
            {matterTypesDisplayValues && matterTypesDisplayValues.length > 0 && (
              <span className="suggested-field-popup__option-matching-matter-types">
                {matterTypesDisplayValues.join(", ")}
              </span>
            )}
          </div>
        </div>
      );
    });
  };

  const renderSelectedOptions = () => {
    return renderLookupOptions(displayOptions.filter((option) => hasMatchingId(checkedOptions, option.id)));
  };

  const renderUnselectedOptions = () => {
    return renderLookupOptions(displayOptions.filter((option) => !hasMatchingId(checkedOptions, option.id)));
  };

  return (
    <>
      <div className="suggested-field-popup__toolbar">
        {renderLookupOperators}
        {operator !== "isempty" && operator !== "isnotempty" && fieldOptions && fieldOptions.length > 10 && (
          <div className="suggested-field-popup__search-options">
            <input
              placeholder={t("Type here to filter list")}
              value={optionSearchText}
              onChange={(e) => setOptionSearchText(e.target.value)}
              tabIndex={0}
            />
          </div>
        )}
      </div>
      {operator !== "isempty" && operator !== "isnotempty" && (
        <>
          {/* <div className="suggested-field-popup__option">
            <Checkbox isChecked={displaySelected} onCheckChange={handleToggleDisplaySelected} tabIndex={0} />
            <strong>{t("Display selected and matching only")}</strong>
          </div> */}
          {!displaySelected && (
            <div className="suggested-field-popup__option-container">
              <Checkbox isChecked={isAllOptionsSelected} onCheckChange={handleToggleAllOptionsSelected} tabIndex={0} />
              <strong>{t("Select All")}</strong>
            </div>
          )}
          <div className="suggested-field-popup__options-list" tabIndex={0}>
            {renderSelectedOptions()}
            {renderUnselectedOptions()}
          </div>
        </>
      )}
    </>
  );
}

SuggestedFieldPopupLookup.propTypes = {
  field: PropTypes.object,
  parent: PropTypes.string,
  operator: PropTypes.string,
  valueArray: PropTypes.array,
  onOperatorChange: PropTypes.func,
  onValueChange: PropTypes.func,
  onIsApplicableChange: PropTypes.func,
};

export default SuggestedFieldPopupLookup;
