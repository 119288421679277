import { createSlice } from "@reduxjs/toolkit";
import { addListTypeEntry } from "api/lookup";

// Redux global state component for storing all lookups from the database
export const lookupSlice = createSlice({
  name: "lookup",
  initialState: {
    global: [],
    customerSpecific: null,
  },
  reducers: {
    setLookup: (state, action) => {
      if (state.global.find((lookup) => lookup.name === action.payload.name)) return;
      let lookups = [...state.global];
      lookups.push({ name: action.payload.name, lookup: action.payload.lookup });
      state.global = lookups;
    },
    addLookup: (state, action) => {
      if (state.global.find((lookup) => lookup.name === action.payload.name)) return;
      let lookups = [...state.global];
      lookups.push({ name: action.payload.name, lookup: action.payload.lookup });
      state.global = lookups;
    },
    updateLookup: (state, action) => {
      const table = state.global.find((table) => table.name === action.payload.name);
      if (!table) return;
      const lookup = table.lookup.find((lookup) => lookup.id === action.payload.lookupId);
      lookup[action.payload.fieldName] = action.payload.newValue;
    },
    removeLookup: (state, action) => {
      state.global = state.global.filter((lookup) => lookup.name !== action.payload.name);
    },
    addLookupEntry: (state, action) => {
      let table = state.global.find((lookup) => lookup.name === action.payload.name);
      if (!table) return;
      table.lookup.push(action.payload.lookup);
    },
    updateLookupEntry: (state, action) => {
      let table = state.global.find((lookup) => lookup.name === action.payload.name);
      if (!table) return;
      let entry = table.lookup.find((entry) => entry.id === action.payload.lookupId);
      if (!entry) return;
      entry[action.payload.fieldName] = action.payload.newValue;
    },
    removeLookupEntry: (state, action) => {
      let table = state.global.find((lookup) => lookup.name === action.payload.name);
      if (!table) return;
      table.lookup = table.lookup.filter((entry) => entry.id !== action.payload.lookupId);
    },
  },
});

export const {
  addLookup,
  updateLookup,
  removeLookup,
  setLookup,
  addLookupEntry,
  removeLookupEntry,
  updateLookupEntry,
} = lookupSlice.actions;

export default lookupSlice.reducer;
