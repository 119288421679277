import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import DataGridColumnHeader from "./DataGridColumnHeader";
import { FaEllipsisV } from "react-icons/fa";
import Checkbox from "components/global/Checkbox";
import { useNavigate } from "react-router-dom";
import { columnSortChange, doInitialSort, selectAll, selectedChange, setRenderData } from "redux/searchResultsSlice";
import { t } from "locale/dictionary";
import { getDataTypeForField, getLookupValueForField, getSubTypeForField } from "utilities/datafield";
import { labelTypes } from "utilities/constants";
import { getCountryNameFromIsoCode } from "utilities/countries";
import { getDateDisplayValue } from "utilities/dateTime";
import { transformSearchResultData } from "utilities/searchResults";
import MatterRecordUniversalPopup from "../matter-record/UniversalPopup";
import { idsAreEqual } from "utilities/stringAndArray";
import { useRef } from "react";

// Component for the Search Results Data Grid
function SearchResultsDataGrid({
  isEmbedded,
  searchedByLinkTypeId,
  isBatchCreate,
  isBatchImport,
  isPriority,
  isReadOnly,
  onSelectChange,
  onSelectAllChange,
  searchText,
}) {
  const queryType = useSelector((state) => state.simpleSearch).currentSearch.queryType;
  const searchResults = useSelector((state) => state.searchResults).currentSearch;
  const matterState = useSelector((state) => state.matter);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRecordPopupVisible, setIsRecordPopupVisible] = useState(false);
  const [popupRecordId, setPopupRecordId] = useState(null);

  const hasInitialSortSetRef = useRef(false);

  const record = matterState.records[matterState.selectedRecordIndex];

  const results = searchResults.results;
  const pagination = searchResults.pagination;
  const selected = searchResults.selected;
  const renderData = searchResults.renderData;
  const images = searchResults.images;

  //console.log("Render Data in SearchResultsDataGrid: " + renderData.map((row) => row.key).join(", "));

  const isAllSelected = results && results[0] && results[0].data.length === selected.length;

  // useEffect(() => {
  //   console.log("SearchResultsDataGrid: searchResults.sort: ", searchResults.sort);
  // }, [searchResults.sort]);

  useEffect(() => {
    if (!results || results.length === 0) return;
    let [transformedData, linkTypes] = transformSearchResultData(results, queryType, searchedByLinkTypeId);
    // If adding connected matters, remove any existing connected matters for this record within the search results
    if (isEmbedded) {
      transformedData = transformedData.filter(
        (row) => !record.links.some((link) => idsAreEqual(link.matterLink_MatterId, row.matterKey))
      );
      // Also remove any self-reference to the base record that is being connected to
      transformedData = transformedData.filter((row) => !idsAreEqual(record.matter_MatterId, row.matterKey));
    }

    dispatch(setRenderData(transformedData));
    //setSelectedLinkTypes(linkTypes);
    // Initialize the sort order to the first five columns, ascending
    if (!hasInitialSortSetRef.current && results.length > 0) {
      hasInitialSortSetRef.current = true;
      dispatch(doInitialSort());
    }
  }, [results, searchedByLinkTypeId]);

  const handleSelectAllClick = () => {
    dispatch(selectAll({ isAllSelected: !isAllSelected, fieldName: "key" }));
    if (isBatchImport) onSelectAllChange(!isAllSelected);
  };

  const handleCheckboxClick = (row, prevSelectState) => {
    dispatch(
      selectedChange({
        id: row.key,
        singleSelection: isBatchCreate && !isPriority,
      })
    );
    if (searchResults.isBatchCreate) onSelectChange(row.matterKey);
    if (isBatchImport) onSelectChange(row.key, !prevSelectState);
  };

  const handleEllipsisClick = (id) => {
    alert(`Ellipsis clicked with ID: ${id}`);
  };

  const handleNavigateToMatter = (id) => {
    if (isBatchCreate || isBatchImport) {
      setIsRecordPopupVisible(true);
      setPopupRecordId(id);
    } else {
      if (selected.length > 0 && !selected.includes(`${id}`)) {
        dispatch(selectedChange({ id }));
      }
      navigate(`/results/matter/${id}`, { replace: false });
    }
  };
  const handleRowClick = (row, prevSelectState) => {
    if (isEmbedded) handleCheckboxClick(row, prevSelectState);
    if (!row.matterKey) return;
    handleNavigateToMatter(row.matterKey);
  };

  // const onLinkTypeSelectChange = (matterKey, optionId) => {
  //   let linkTypes = [...selectedLinkTypes];
  //   let selectedLinkType = linkTypes.find((linkType) => linkType.matterKey === matterKey);
  //   if (!optionId) {
  //     linkTypes = linkTypes.filter((linkType) => linkType.matterKey !== matterKey);
  //   } else if (selectedLinkType) selectedLinkType.optionId = optionId;
  //   else linkTypes.push({ matterKey, optionId });
  //   setSelectedLinkTypes(linkTypes);
  // };

  const renderDataHeaders = results.map((col, id) => {
    if (isBatchImport && col.fieldName === "import_IsSelected") return null;
    return <DataGridColumnHeader key={id} resultField={col.fieldName} columnIndex={id} />;
  });

  let renderNumResultsFound = null;
  if (!renderData || (renderData.length === 0 && searchText?.length === 0)) {
    renderNumResultsFound = <div className="search-results__num-results">{t("Search results will appear here")}</div>;
  } else if (isBatchCreate) {
    renderNumResultsFound = (
      <div className="search-results__num-results">{`${renderData.length > 0 ? renderData.length : "No"} ${t(
        "results found"
      )}`}</div>
    );
  }

  const dataTdClassName = "data-grid__cell";
  const actionTdClassName = `${dataTdClassName} flex-item-center`;

  const renderDataRow = (row, rowIndex) => {
    if (rowIndex < pagination.lowIndex || rowIndex > pagination.highIndex) return null;
    const isSelected = selected.includes(row.key.toString());
    const dataTrClassName = isBatchImport ? (row.matterKey ? "data-grid__row--duplicate" : "") : "";

    return (
      <tr className={dataTrClassName} key={rowIndex}>
        {row.rowData.map((value, colIndex) => renderDataCell(value, colIndex, row, isSelected))}
        <td className={actionTdClassName}>
          {isReadOnly ? (
            isSelected ? (
              "Yes"
            ) : (
              "No"
            )
          ) : (
            <Checkbox isChecked={isSelected} onCheckChange={() => handleCheckboxClick(row, isSelected)} />
          )}
        </td>
        {!isEmbedded && !isBatchCreate && !isBatchImport && (
          <td className={actionTdClassName} onClick={() => handleEllipsisClick(row.matterKey)}>
            <FaEllipsisV />
          </td>
        )}
      </tr>
    );
  };

  const renderDataCell = (value, colIndex, row, isSelected) => {
    let displayValue = value;
    const fieldName = results?.length > colIndex && results[colIndex].fieldName;
    if (isBatchImport && fieldName === "import_IsSelected") return null;
    const dataType = getDataTypeForField(fieldName);

    if (queryType?.code === "MATTERAUDIT") {
      displayValue = renderMatterAudit(displayValue, fieldName, row);
    } else {
      displayValue = renderStandard(displayValue, fieldName, row);
    }

    if (dataType === labelTypes.DATE) {
      displayValue = getDateDisplayValue(value);
    }

    return (
      <td key={colIndex} className={dataTdClassName} onClick={() => handleRowClick(row, isSelected)}>
        {displayValue}
      </td>
    );
  };

  const renderMatterAudit = (displayValue, fieldName, row) => {
    if (fieldName === "matterAudit_OldValue" || fieldName === "matterAudit_NewValue") {
      if (row.dataFieldName === "countries") {
        displayValue = getCountryNameFromIsoCode(displayValue);
      } else {
        const dataType = getDataTypeForField(fieldName);
        if (dataType === labelTypes.LOOKUP) {
          displayValue = getLookupValueForField(row.dataFieldName, displayValue);
        }
      }
    }
    return displayValue;
  };

  const renderStandard = (displayValue, fieldName, row) => {
    const subType = getSubTypeForField(fieldName);
    switch (subType) {
      case "matterImageId":
      case "projectImageId": {
        const key = subType === "matterImageId" ? row.matterKey : row.key;
        const image = images.find((i) => idsAreEqual(i.matterId, key));
        if (image) {
          displayValue = <img className="data-grid__img" src={image.base64data} alt="matter thumbnail" />;
        }
      }
    }
    return displayValue;
  };

  // Then render the transformed row->column data as a table
  const renderDataRows = renderData?.map((row, rowIndex) => renderDataRow(row, rowIndex));

  //MAIN RENDER
  return (
    <>
      {renderNumResultsFound}
      <div className="search-results__data-grid">
        {renderData?.length > 0 && (
          <table>
            <thead>
              <tr>
                {renderDataHeaders}
                <th>
                  {(!isBatchCreate || isPriority || isBatchImport) &&
                    (isReadOnly ? (
                      "Selected"
                    ) : (
                      <Checkbox isChecked={isAllSelected} onCheckChange={handleSelectAllClick} />
                    ))}
                </th>
                {!isEmbedded && !isBatchCreate && !isBatchImport && (
                  <th>
                    <FaEllipsisV />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{renderDataRows}</tbody>
          </table>
        )}
        {isRecordPopupVisible && (
          <MatterRecordUniversalPopup recordId={popupRecordId} onClose={() => setIsRecordPopupVisible(false)} />
        )}
      </div>
    </>
  );
}

SearchResultsDataGrid.propTypes = {
  isEmbedded: PropTypes.bool,
  searchedByLinkTypeId: PropTypes.number,
  isBatchCreate: PropTypes.bool, // if true, then render the grid for the batch create page
  isBatchImport: PropTypes.bool, // if true, then render the grid for the batch import page
  isPriority: PropTypes.bool, // batch create usage only - if false, then allow only single selection
  isReadOnly: PropTypes.bool,
  onSelectChange: PropTypes.func,
  searchText: PropTypes.string,
};

export default SearchResultsDataGrid;
