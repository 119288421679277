import { PropTypes } from "prop-types";
import RichTextEditor from "./text-editor/RichTextEditor";
import MergeObjectList from "./objects/MergeObjectList";

function FormatMailMerge({ format, matterTypeIds }) {
  return (
    <>
      <RichTextEditor format={format} matterTypeIds={matterTypeIds} />
      <MergeObjectList format={format} matterTypeIds={matterTypeIds} />
    </>
  );
}

FormatMailMerge.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default FormatMailMerge;
