import store from "redux/store";
import { getImageData } from "api/image";
import { getDataTypeForField, getDisplayNameForField, getLookupValueForField, getSubTypeForField } from "./datafield";
import { addImage } from "redux/searchResultsSlice";
import { labelTypes } from "./constants";
import { getBooleanDisplayValue, idsAreEqual } from "./stringAndArray";

export function parseSearchResponse(response) {
  let cols = [];
  response.header.dataFieldNames.forEach((fieldName) => {
    const displayName = getDisplayNameForField(fieldName);
    const dataType = getDataTypeForField(fieldName);
    cols.push({ fieldName, displayName, dataType });
  });

  for (let colnum = 0; colnum < cols.length; colnum++) {
    const col = cols[colnum];
    let data = [];
    response.body &&
      response.body.forEach((row, index) => {
        const key = row.key;
        const matterKey = row.matterKey;
        const matterTypeId = row.matterTypeId;
        const apiValue = row.values[colnum];
        let dataRow = { displayValue: apiValue ?? "", key, matterKey, matterTypeId, index }; // Set any null apiValues to a displayValue of empty string
        if (getSubTypeForField(col.fieldName) !== "projectImageId") {
          switch (col.dataType) {
            case labelTypes.STRING: {
              if (Array.isArray(apiValue)) {
                const displayArray = apiValue.filter((item) => item.length > 0);
                dataRow.displayValue = displayArray.join(", ");
              }
              break;
            }
            case labelTypes.LOOKUP: {
              dataRow.displayValue = getLookupValueForField(col.fieldName, apiValue);
              break;
            }
            case labelTypes.BOOLEAN: {
              dataRow.displayValue = getBooleanDisplayValue(apiValue);
              break;
            }
            // Dates need to be sorted in the input displayName order (yyyy-MM-dd), but we output them in the user's locale at the UI endpoint
            case labelTypes.DATE:
              break;
            default:
              break;
          }
        }
        data.push(dataRow);
      });
    col.data = data;
  }
  return cols;
}

const getLastRowIndex = (results) => {
  let longestArrayLength = 0;
  results.forEach((result) => {
    const dataLength = result?.data?.length ?? 0;
    if (dataLength > longestArrayLength) longestArrayLength = dataLength;
  });

  return longestArrayLength;
};

export function transformSearchResultData(results, queryType, searchedByLinkTypeId) {
  const state = store.getState();
  // data import data lengths vary

  const lastRowIndex = getLastRowIndex(results);
  let rowIndex = 0;
  let transformedData = [];
  let linkTypes = [];
  const images = state.searchResults.currentSearch.images;
  //const projectId = state.searchResults.currentSearch.projectId;

  // We need to transform the incoming data from column->row to row->column
  while (rowIndex < lastRowIndex) {
    let key = null;
    let matterKey = null;
    let matterTypeId = null;
    let dataFieldName = null;
    const rowData = results.map((col) => {
      const data = col.data[rowIndex];
      key = data?.key;
      matterKey = data.matterKey;
      matterTypeId = data.matterTypeId;
      let returnValue = data.displayValue;
      const subType = getSubTypeForField(col.fieldName);

      // Special handling for recording field name for old value and new value fields, and for displaying translated field name
      if (queryType?.code === "MATTERAUDIT") {
        if (data.displayValue && data.displayValue.indexOf("_") !== -1) {
          returnValue = getDisplayNameForField(data.displayValue);
        }
        if (col.fieldName === "matterAudit_DataFieldName") {
          dataFieldName = data.displayValue;
        }
      } else if (subType === "matterImageId" && returnValue.length > 0) {
        if (images.find((image) => idsAreEqual(image.matterId, matterKey))) return;
        try {
          getImageData(...returnValue, matterKey).then((base64data) => {
            const newImage = { matterId: matterKey, base64data: base64data };
            store.dispatch(addImage(newImage));
          });
        } catch (e) {
          //   displayValue = value;
        }
      }
      // TODO: Re-architect this to prevent infinite loop in SearchResultsDataGrid.jsx useEffect
      // OR get the API to return the image data in the search results
      // The adding of images to the searchResults store is causing an infinite loop in the SearchResultsDataGrid.jsx useEffect
      // else if (subType === "projectImageId") {
      //   if (returnValue === true) {
      //     if (images.find((image) => idsAreEqual(image.matterId, key))) return;
      //     try {
      //       getBatchImportImageData(projectId, key).then((base64data) => {
      //         const newImage = { matterId: key, base64data: base64data };
      //         store.dispatch(addImage(newImage));
      //       });
      //     } catch (e) {
      //       returnValue = "";
      //     }
      //   } else {
      //     returnValue = "";
      //   }
      // }

      return returnValue;
    });
    transformedData.push({ key, matterKey, matterTypeId, rowData, dataFieldName });
    if (searchedByLinkTypeId) {
      linkTypes.push({ matterKey, optionId: searchedByLinkTypeId });
    }
    rowIndex++;
  }

  return [transformedData, linkTypes];
}
