import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useDrop } from "react-dnd";
import { PropTypes } from "prop-types";
import { updateMailMergeHtml } from "redux/reportSlice";
import { getMailMergeObjectTypes, getOutputDataFieldGroups } from "utilities/reportEditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Alignment,
  Bold,
  ClassicEditor,
  Essentials,
  FontColor,
  FontBackgroundColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  Italic,
  ListProperties,
  Mention,
  Paragraph,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Undo,
  Underline,
} from "ckeditor5";
import { SlashCommand, MergeFields } from "ckeditor5-premium-features";
import "ckeditor5/ckeditor5.css";
import "ckeditor5-premium-features/ckeditor5-premium-features.css";

function HtmlEditorCKEditor({ format, matterTypeIds }) {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const [editorKey, setEditorKey] = useState(0);
  const [fieldGroups, setFieldGroups] = useState([]);
  const [mergeFields, setMergeFields] = useState({ definitions: [] });
  const [objectTypes] = useState(getMailMergeObjectTypes());

  useEffect(() => {
    setFieldGroups(getOutputDataFieldGroups(format.queryType, format.formatType, matterTypeIds));
  }, [format.queryType, format.formatType, matterTypeIds]);

  const [, drop] = useDrop({
    accept: "box",
    drop: (item) => handleDrop(item),
  });

  const handleDrop = (item) => {
    const editor = editorRef.current.editor;
    const model = editor.model;
    const insertPosition = model.document.selection.getFirstPosition();

    const mergeField = "{{" + item.name + "}}";

    model.change((writer) => {
      writer.insertText(mergeField, insertPosition);
    });
  };

  const handleChange = (data) => {
    dispatch(updateMailMergeHtml({ html: data }));
  };

  useEffect(() => {
    const tableObjects =
      format.formatMailMerge.objects
        ?.filter((object) => object.mailMergeFormatObjectType === 1)
        .map((object) => {
          const dataSourceLabel = object?.dataSourceName ?? null;
          const objectType = objectTypes.find((objectType) => objectType.id === object.mailMergeFormatObjectType);
          const objectLabel = `${objectType.displayValue}: ${object?.name ?? object?.id} ${
            dataSourceLabel ? `(${dataSourceLabel})` : ""
          }`;

          return {
            id: `__objectId__${object.id}`,
            label: objectLabel,
            type: "block",
            height: "200",
          };
        }) ?? [];

    const customTables =
      tableObjects.length > 0
        ? [{ groupId: "Custom Tables", groupLabel: "Custom Tables", definitions: tableObjects }]
        : [];

    const mergeFields = {
      definitions: [
        ...customTables,
        ...fieldGroups.map((group) => {
          return {
            groupId: group.name,
            groupLabel: group.label,
            definitions: group.dataFields.map((field) => {
              return {
                id: field.name,
                label: field.displayName,
              };
            }),
          };
        }),
      ],
    };
    setMergeFields(mergeFields);
    setEditorKey((prevKey) => prevKey + 1);
  }, [format.formatMailMerge.objects, fieldGroups, objectTypes]);

  return (
    <div key={editorKey} ref={drop}>
      <CKEditor
        editor={ClassicEditor}
        onReady={(editor) => {
          editorRef.current = { editor };
        }}
        onChange={(_event, editor) => {
          handleChange(editor.getData());
        }}
        config={{
          toolbar: {
            items: [
              "undo",
              "redo",
              "|",
              "insertMergeField",
              "previewMergeFields",
              "|",
              "heading",
              "|",
              "fontfamily",
              "fontsize",
              "fontColor",
              "fontBackgroundColor",
              "|",
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "subscript",
              "superscript",
              "|",
              "alignment",
              "|",
              "insertTable",
              "|",
              "link",
              "uploadImage",
              "blockQuote",
              "codeBlock",
              "|",
              "bulletedList",
              "numberedList",
              "todoList",
              "outdent",
              "indent",
            ],
            // shouldNotGroupWhenFull: false,
          },
          table: {
            contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableProperties", "tableCellProperties"],
          },
          heading: {
            options: [
              {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
              },
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
              },
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
              {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
              },
              {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
              },
              {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
              },
            ],
          },
          plugins: [
            Alignment,
            Bold,
            Essentials,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            Heading,
            HorizontalLine,
            Italic,
            ListProperties,
            Mention,
            MergeFields,
            Paragraph,
            SlashCommand,
            Strikethrough,
            Subscript,
            Superscript,
            Table,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            Underline,
            Undo,
          ],
          licenseKey:
            "NW8wU2xEUEE1VExEWGNoNU5wRk9sQkMyM25INjh5dTlEWkpIcUFnY0NHRU9ZU1J4L245bG5xTyt2ODlvTWc9PS1NakF5TkRFeE1Eaz0=",
          mention: {
            // Mention configuration
          },
          mergeFields: mergeFields,
          initialData: format.formatMailMerge.messageBodyHtml,
        }}
      />
    </div>
  );
}

HtmlEditorCKEditor.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
  html: PropTypes.object,
  onChange: PropTypes.func,
};

export default HtmlEditorCKEditor;
