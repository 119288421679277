import { PropTypes } from "prop-types";
import { MailMergeObjectTypes } from "utilities/reportEditor";
import TableObject from "./TableObject";

function MergeObjectItem({ format, matterTypeIds, mailMergeObject }) {
  switch (mailMergeObject.mailMergeFormatObjectType) {
    case MailMergeObjectTypes.TABLE:
      return <TableObject format={format} matterTypeIds={matterTypeIds} mailMergeObject={mailMergeObject} />;
    default:
      return null;
  }
}

MergeObjectItem.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.arrayOf(PropTypes.number),
  mailMergeObject: PropTypes.object,
};

export default MergeObjectItem;
