import { useState } from "react";
import PropTypes from "prop-types";
import { ReportOutputTypes } from "utilities/reportEditor";
import { FaPlay, FaPencilRuler, FaCopy, FaSave, FaGrin } from "react-icons/fa";
import { executeReportRaw, saveReportFieldChanges, transformReportType } from "api/report";
import ToolbarButton from "./ToolbarButton";
import GridToolbar from "../format-grid/toolbar/GridToolbar";
import MailMergeToolbar from "../format-mailmerge/toolbar/Toolbar";
import PivotToolbar from "../format-pivot/toolbar/Toolbar";
import { ResultSetTypes } from "utilities/reportEditor";
import { base64ToBlob } from "utilities/stringAndArray";
import { saveAs } from "file-saver";
import ToolbarProperties from "./ToolbarProperties";
import { saveReportFormat } from "api/report";
import { t } from "locale/dictionary";
import ToolbarSortOrder from "../format-grid/toolbar/ToolbarSortOrder";
import ToolbarOutputPicker from "../format-grid/toolbar/ToolbarOutputPicker";
import ReportCopyPopup from "../common/ReportCopyPopup";
import { FormatTypes } from "utilities/constants";
import { MdOutlinePublishedWithChanges, MdOutlineUnpublished } from "react-icons/md";

function Toolbar({ reportId, report, format, output, designView, setOutput, setDesignView, selected }) {
  const [reportRunning, setReportRunning] = useState(false);

  const [outputType, setOutputType] = useState(ReportOutputTypes.DEFAULT);
  const [reportSaving, setReportSaving] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);

  const onRunReportClick = async () => {
    setReportRunning(true);
    const response = await executeReportRaw(reportId, outputType, format);
    setReportRunning(false);
    if (response) {
      switch (response.type) {
        case ResultSetTypes.EXCEL:
        case ResultSetTypes.WORD:
          setOutput(null);
          setDesignView(null);
          saveAs(base64ToBlob(response.file.base64String), response.file.fileName);
          break;
        case ResultSetTypes.MAP:
        case ResultSetTypes.PIVOT:
        case ResultSetTypes.TABLE:
          setOutput(response);
          setDesignView(false);
          break;
        default:
      }
    }
  };

  const onReportSaveClick = async () => {
    setReportSaving(true);
    await saveReportFormat();
    setReportSaving(false);
  };

  const onDesignViewClick = () => {
    if (output != null) {
      if (outputType === ReportOutputTypes.DEFAULT) {
        setDesignView((prevValue) => !prevValue);
      } else {
        setDesignView(true);
      }
    }
  };

  const onOutputTypeClick = (outputType) => {
    setOutputType(outputType);
  };

  const handleTogglePublishStatus = () => {
    saveReportFieldChanges("isPublished", !report.isPublished);
  };

  const handleSwitchToSimpleView = () => {
    transformReportType(report, FormatTypes.LIST);
  };

  let formatToolbar = null;

  switch (format.formatType) {
    case FormatTypes.GRID:
      formatToolbar = (
        <GridToolbar
          reportId={reportId}
          format={format}
          selected={selected}
          outputType={outputType}
          onOutputTypeClick={onOutputTypeClick}
        />
      );
      break;
    case FormatTypes.MAILMERGE:
      formatToolbar = <MailMergeToolbar />;
      break;
    case FormatTypes.PIVOT:
      formatToolbar = <PivotToolbar />;
      break;
    default:
  }

  return (
    <div className="report-editor__toolbar">
      <div className="toolbar__section">
        <div className="section__title">{t("Report Actions")}</div>
        <div className="section__content">
          <ToolbarProperties report={report} />
          <ToolbarButton tooltipText="Save" onClick={() => onReportSaveClick()} disabled={reportSaving}>
            <FaSave className={reportSaving ? "button__icon button__execute" : "button__icon"} />
          </ToolbarButton>
          <ToolbarButton tooltipText="Save a Copy" onClick={() => setShowCopyPopup(true)}>
            <FaCopy />
          </ToolbarButton>
          <ToolbarButton tooltipText="Switch to Simple View" onClick={handleSwitchToSimpleView}>
            <FaGrin />
          </ToolbarButton>
          <ToolbarButton
            tooltipText={report?.isPublished ? "Unpublish" : "Publish"}
            onClick={handleTogglePublishStatus}
          >
            {report?.isPublished ? <MdOutlineUnpublished /> : <MdOutlinePublishedWithChanges />}
          </ToolbarButton>
          <ToolbarButton
            tooltipText={designView ? "Run Report" : "Back to Editor"}
            onClick={() => (designView ? onRunReportClick() : onDesignViewClick())}
            disabled={reportRunning}
          >
            {designView ? (
              <FaPlay className={reportRunning ? "button__icon button__execute" : "button__icon"} />
            ) : (
              <FaPencilRuler className="button__icon" />
            )}
          </ToolbarButton>
          <ToolbarSortOrder format={format} report={report} />
          <ToolbarOutputPicker outputType={outputType} onOutputTypeClick={onOutputTypeClick} />
        </div>
      </div>
      {formatToolbar}
      {showCopyPopup && (
        <ReportCopyPopup report={report} clickSource="editor" onClose={() => setShowCopyPopup(false)} />
      )}
    </div>
  );
}

Toolbar.propTypes = {
  reportId: PropTypes.number,
  format: PropTypes.object,
  output: PropTypes.object,
  designView: PropTypes.bool,
  setOutput: PropTypes.func,
  setDesignView: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.object),
};

export default Toolbar;
