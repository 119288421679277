import { addUserToAccount, changeUsersUserGroup, deleteUserFromAccount } from "api/security";
import Delete from "components/global/Delete";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { findById } from "utilities/stringAndArray";
import SecurityColumnHeader from "./SecurityColumnHeader";
import { labelTypes } from "utilities/constants";

export default function AccountUsers() {
  const securityState = useSelector((state) => state.security);
  const params = useParams();

  const [users, setUsers] = useState([]);
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);
  const [sortParams, setSortParams] = useState({
    sortCol: "name",
    sortAsc: true,
  });

  const customerId = parseInt(params.customerId);
  const accountId = parseInt(params.accountId);
  const currentAccount = securityState.currentAccount;

  useEffect(() => {
    if (!currentAccount || !currentAccount.users) return;
    let usersLocal = [...currentAccount.users].map((accountUser) => {
      const userData = {
        ...findById(securityState.currentCustomer.users, accountUser.customerUserId),
        groupId: accountUser.userGroupId,
        groupUserId: accountUser.id,
      };
      return {
        id: accountUser.id,
        fullName: userData.fullName,
        email: userData.email,
        userGroupId: userData.groupId,
        userGroupUserId: userData.groupUserId,
      };
    });

    setUsers(usersLocal);
  }, [currentAccount?.users]);

  const userOptions = securityState?.currentCustomer?.users
    .filter((user) => !currentAccount?.users.map((user) => user.customerUserId).includes(user.id))
    .map((user) => ({ id: user.id, displayValue: user.fullName }));

  const userGroupOptions = currentAccount?.userGroups?.map((group) => ({
    id: group.id,
    displayValue: group.name,
  }));

  const handleAddUserToAccount = (userId) => {
    addUserToAccount(customerId, accountId, userId);
    setIsAddingNewUser(false);
  };

  const handleUpdateSortParams = (sortedData, newSortParams) => {
    setUsers(sortedData);
    setSortParams(newSortParams);
  };

  const renderUserTableBody =
    users?.length > 0 ? (
      users.map((accountUser) => {
        return (
          <tr className="security__table__body-row" key={accountUser.id}>
            <td className="security__table__body-row-cell">
              <Link to={`/customers/${customerId}/accounts/${accountId}/users/${accountUser.id}`}>
                {accountUser.fullName}
              </Link>
            </td>
            <td className="security__table__body-row-cell">{accountUser.email}</td>

            <td className="security__table__body-row-cell ">
              <SuperSelect
                id={accountUser.id}
                selectedOptionId={accountUser.userGroupId}
                options={userGroupOptions}
                onChange={(optionId) =>
                  changeUsersUserGroup(
                    customerId,
                    accountId,
                    accountUser.userGroupUserId,
                    "userGroupId",
                    accountUser.userGroupId,
                    optionId
                  )
                }
              />
            </td>
            <td className="security__table__body-row-cell  clickable">
              <Delete
                message={"remove this user from this Account"}
                onConfirm={() => deleteUserFromAccount(customerId, accountId, accountUser.id)}
              ></Delete>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td className="security__table__body__no-data">{t("No Users")}</td>
      </tr>
    );

  const renderUserTable = (
    <>
      <div className="security__section-title">{t("Users")}</div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              <SecurityColumnHeader
                fieldName="fullName"
                fieldType={labelTypes.STRING}
                displayName="Name"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="email"
                fieldType={labelTypes.STRING}
                displayName="Email"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <SecurityColumnHeader
                fieldName="userGroupId"
                fieldType={labelTypes.DECIMAL}
                displayName="User Group"
                tableData={users}
                sortParams={sortParams}
                onUpdateSortParams={handleUpdateSortParams}
              />
              <th className="security__table__header-row-cell">{t("Delete")}</th>
            </tr>
          </thead>
          <tbody className="security__table__body">
            {renderUserTableBody}
            {isAddingNewUser && (
              <tr className="security__table__body-row">
                <td className="security__table__body-row-cell ">
                  <SuperSelect
                    id="userName"
                    selectedOptionId={null}
                    options={userOptions ?? []}
                    placeholderText="Search for User"
                    onChange={(userId) => handleAddUserToAccount(userId)}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!isAddingNewUser && (
        <button className="flex-row-center" onClick={() => setIsAddingNewUser(true)}>
          {t("Add User")}
        </button>
      )}
    </>
  );

  //MAIN RENDER
  return <>{renderUserTable}</>;
}
