import { PropTypes } from "prop-types";

function MailMergeEmailOutput({ onClose, output }) {
  const { ClipboardItem } = window;

  const handleCopy = async (htmlContent) => {
    await navigator.clipboard.write([
      new ClipboardItem({
        "text/html": new Blob([htmlContent], { type: "text/html" }),
        "text/plain": new Blob(["This is plain text fallback"], { type: "text/plain" }),
      }),
    ]);
  };

  if (!output || output?.messages?.length === 0) {
    return null;
  }

  return (
    <div style={{ margin: 20, padding: 20 }}>
      <h3>Email Output</h3>
      <div>
        {output?.messages?.map((message, index) => {
          return (
            <div key={index} style={{ marginBottom: 10 }}>
              <div style={{ display: "flex", gap: 5, marginBotton: 10 }}>
                <button onClick={onClose}>Close</button>
                <button onClick={() => handleCopy(message.body)}>Copy</button>
              </div>
              <div style={{ width: 600, marginTop: 10, marginBottom: 10, padding: 20, borderStyle: "solid" }}>
                <div style={{ userSelect: "auto" }} dangerouslySetInnerHTML={{ __html: message.body }} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

MailMergeEmailOutput.propTypes = {
  onClose: PropTypes.func,
  output: PropTypes.object,
};

export default MailMergeEmailOutput;
