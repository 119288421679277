import { t } from "locale/dictionary";
import Lookups from "./Lookups";
import { getAllLookupValuesForField } from "utilities/datafield";
import SuperSelect from "components/global/SuperSelect";
import { useEffect, useState } from "react";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { useSelector } from "react-redux";

// Configuration container
export default function Configuration() {
  const isLoading = useSelector((state) => state.app).isLoading;
  const [matterTypes, setMatterTypes] = useState([]);
  const [selectedMatterTypeId, setSelectedMatterTypeId] = useState(null);

  useEffect(() => {
    if (isLoading) return;
    const allMatterTypesLocal = getAllLookupValuesForSource("MatterTypes");
    setMatterTypes(allMatterTypesLocal);
  }, [isLoading]);

  return (
    <div className="configuration">
      <div className="configuration__title">{t("Configuration")}</div>
      <div className="configuration__matter-type-container">
        <div className="matter-type-container__label">{t("Select Matter Type")}</div>
        <div className="matter-type-container__select">
          <SuperSelect
            id="matter-type-config"
            options={matterTypes}
            selectedOptionId={selectedMatterTypeId}
            onChange={(value) => setSelectedMatterTypeId(value)}
          />
        </div>
      </div>

      <Lookups selectedMatterTypeId={selectedMatterTypeId} />
    </div>
  );
}
