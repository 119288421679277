import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import FormatGrid from "./format-grid/FormatGrid";
import LoadingModal from "components/global/LoadingModal";
import { FormatTypes } from "utilities/constants";
import FormatMap from "./format-map/FormatMap";
import FormatPivot from "./format-pivot/FormatPivot";
import { FiChevronsLeft } from "react-icons/fi";
import Toolbar from "./toolbar/Toolbar";
import FieldList from "./field-list/FieldList";
import ReportOutput from "../report-output/ReportOutput";
import { t } from "locale/dictionary";
import { PropTypes } from "prop-types";
import FormatMailMerge from "./format-mailmerge/FormatMailMerge";

function ReportEditor({ report, format }) {
  const location = useLocation();
  const reportState = useSelector((state) => state.report);
  const selectedGridElements = reportState.selectedGridElements;

  const [output, setOutput] = useState(null);
  const [designView, setDesignView] = useState(true);

  const reportId = report?.id;

  let formatControl = null;

  console.log("ReportEditor format: ", format);

  switch (format?.formatType) {
    case FormatTypes.GRID:
      formatControl = <FormatGrid key={reportId} format={format} matterTypeIds={report?.matterTypeIds} />;
      break;
    // case FormatTypes.LIST: // Now handled in the "Basic" editor
    //   formatControl = <FormatList key={reportId} format={format} matterTypeIds={report?.matterTypeIds} />;
    //   break;
    case FormatTypes.MAILMERGE:
      formatControl = (
        <FormatMailMerge key={reportId} report={report} format={format} matterTypeIds={report?.matterTypeIds} />
      );
      break;
    case FormatTypes.MAP:
      formatControl = <FormatMap key={reportId} />;
      break;
    case FormatTypes.PIVOT:
      formatControl = <FormatPivot key={reportId} format={format} matterTypeIds={report?.matterTypeIds} />;
      break;
    default:
  }

  let backLinkTo = location?.state?.source || "/reportmenu";
  let backLinkText = "Back to Report Editor Menu";
  if (backLinkTo === "/results") {
    backLinkText = "Back to Search Results";
  }

  const renderHeader = (
    <div className="report-editor__header">
      <div className="report-editor__title-bar">
        <div className="report-editor__title-text">{report?.name}</div>
        <Link to={backLinkTo} className="report-editor__menu-return">
          <FiChevronsLeft /> {t(backLinkText)}
        </Link>
      </div>
      <Toolbar
        designView={designView}
        report={report}
        format={format}
        selected={selectedGridElements}
        reportId={reportId}
        output={output}
        setOutput={setOutput}
        setDesignView={setDesignView}
      />
    </div>
  );

  return (
    <div className="content__report-editor">
      {format && formatControl !== null ? (
        <>
          <FieldList format={format} matterTypeIds={report?.matterTypeIds} />
          <div className="report-editor__main">
            {renderHeader}
            {(designView === null || designView === true) && (
              <div className="report-editor__body">
                {!report.isPublished && (
                  <div className="report__draft-warning">
                    {t("THIS REPORT IS A DRAFT. In order to run this report you will need to publish it.")}
                  </div>
                )}
                {formatControl}
              </div>
            )}
            {designView === false &&
              (format.formatType === FormatTypes.GRID ||
                format.formatType === FormatTypes.LIST ||
                format.formatType === FormatTypes.MAP ||
                format.formatType === FormatTypes.PIVOT) && (
                <div className="report-editor__output-container">
                  <ReportOutput output={output} />
                </div>
              )}
          </div>
        </>
      ) : (
        <LoadingModal />
      )}
    </div>
  );
}

ReportEditor.propTypes = {
  report: PropTypes.object,
  format: PropTypes.object,
};

export default ReportEditor;
