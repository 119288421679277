import { getDataTypeForField } from "./datafield";
import { compareByFieldValue } from "./stringAndArray";

// Universal sorting function for columns in a table
export const sortColumn = (fieldName, fieldTypeIn, tableData, sortParams) => {
  const sortedData = [...tableData];
  let newSortParams = { ...sortParams };
  // Determine whether to toggle the sort direction or not
  newSortParams.sortAsc = fieldName === sortParams.sortCol ? !sortParams.sortAsc : true;
  newSortParams.sortCol = fieldName;
  const fieldType = fieldTypeIn ?? getDataTypeForField(fieldName);
  sortedData.sort((a, b) => compareByFieldValue(a, b, fieldName, fieldType, newSortParams.sortAsc));
  return { sortedData, newSortParams };
};
