import {
  removeSearchFilter,
  setBatchImportProject,
  updateCompanyMapping,
  updateSearchFilter,
  updateStatusMapping,
} from "redux/batchImportSlice";
import { modifySearchResult, modifySearchResults } from "redux/searchResultsSlice";
import { addFilter } from "redux/simpleSearchSlice";
import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";

//SEARCH FILTERS
// export async function addBatchImportSearchFilter(customerId, projectId, body) {
//   const response = await fetchWrapper.post(`customers/${customerId}/projects/${projectId}/searchFilters`, body);
//   store.dispatch(addFilter(response));
// }
// export async function updateBatchImportSearchFilter(customerId, projectId, filterId, fieldName, initialValue, value) {
//   const body = [
//     { path: `/${fieldName}`, op: "test", value: initialValue },
//     { path: `/${fieldName}`, op: "replace", value },
//   ];

//   await fetchWrapper.patch(`customers/${customerId}/projects/${projectId}/searchFilters/${filterId}`, body);
//   store.dispatch(updateSearchFilter({ fieldName, filterId, value }));
// }
// export async function deleteBatchImportSearchFilter(customerId, projectId, filterId) {
//   await fetchWrapper.delete(`customers/${customerId}/projects/${projectId}/searchFilters/${filterId}`);
//   store.dispatch(removeSearchFilter(filterId));
// }

//IMAGE DATA
export async function getBatchImportImageData(projectId, searchRecordId, asBase64 = false) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const requestOptions = {
    method: "GET",
    headers: { Authorization: state.app.bearerToken },
  };

  let url = `${process.env.REACT_APP_API_ROOT}customers/${customerId}/projects/${projectId}/imagedata/${searchRecordId}`;
  if (!asBase64) url += "/formdata";

  const response = await fetch(url, requestOptions);

  if (asBase64) {
    const base64response = await response.text();
    return `data:image;base64,${base64response}`;
  }
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

//RECORD SELECT
export async function updateBatchImportSearchRowSelect(customerId, projectId, rowId, isSelected) {
  await fetchWrapper.put(
    `customers/${customerId}/projects/${projectId}/searchrowselect/${rowId}?isSelected=${isSelected}`
  );
  store.dispatch(
    modifySearchResult({ key: rowId, displayValue: isSelected ? "Yes" : "No", fieldName: "import_IsSelected" })
  );
}
export async function updateBatchImportSearchRowSelectAll(customerId, projectId, isSelected) {
  await fetchWrapper.put(`customers/${customerId}/projects/${projectId}/searchrowselect?isSelected=${isSelected}`);
  store.dispatch(modifySearchResults({ displayValue: isSelected ? "Yes" : "No", fieldName: "import_IsSelected" }));
}

export async function batchImportPrepare(customerId, projectId) {
  const response = await fetchWrapper.post(`customers/${customerId}/projects/${projectId}/prepare`);
  store.dispatch(setBatchImportProject(response));
  return response;
}

export async function batchImportPreview(customerId, projectId) {
  const response = await fetchWrapper.post(`customers/${customerId}/projects/${projectId}/preview`);
  store.dispatch(setBatchImportProject(response));
  return response;
}

// TODO: This should be replaced by the common API call for generating matters with a Project (see project.js generateMatter)
// DELETE THIS WHEN IT'S CONFIRMED WE'RE USING THE COMMON PROJECT COMPONENT FOR GENERATING MATTERS
export async function batchImportGenerate(customerId, projectId) {
  const response = await fetchWrapper.post(`customers/${customerId}/projects/${projectId}/generate`);
  store.dispatch(setBatchImportProject(response));
  return response;
}

// MAPPINGS
//?? COMBINE MAPPINGS INTO ONE FUNCTION?
export async function updateBatchImportStatusMapping(customerId, projectId, mapId, fieldName, initialValue, value) {
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/projects/${projectId}/statusmaps/${mapId}`, body);
  store.dispatch(updateStatusMapping({ mapId, fieldName, value }));
}
export async function updateBatchImportCompanyMapping(customerId, projectId, mapId, fieldName, initialValue, value) {
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/projects/${projectId}/companymaps/${mapId}`, body);
  store.dispatch(updateCompanyMapping({ mapId, fieldName, value }));
}
