import MergeObjectItem from "./MergeObjectItem";
import { PropTypes } from "prop-types";
import CreateObject from "./CreateObject";

function MergeObjectList({ format, matterTypeIds }) {
  return (
    <>
      <CreateObject format={format} />
      {format.formatMailMerge.objects?.map((object) => (
        <MergeObjectItem key={object.id} format={format} mailMergeObject={object} matterTypeIds={matterTypeIds} />
      ))}
    </>
  );
}

MergeObjectList.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default MergeObjectList;
