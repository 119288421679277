import store from "redux/store";
import { deleteRecord, updateOwnership, updateRecord, updateSimpleFieldValue } from "redux/reportSlice";
import { fetchWrapper } from "utilities/fetchWrapper";
import { OwnerTypes } from "utilities/reportEditor";
import { idsAreEqual } from "utilities/stringAndArray";

export async function getReportById(reportId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/reports/${reportId}`);
  return response;
}

export async function getAllReports() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/reports`);
  return response;
}

export async function createReportRecord(name, description, queryTypeId, matterTypeIds, formatTypeId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = { name, description, queryTypeId, matterTypeIds, formatTypeId };
  const response = await fetchWrapper.post(`customers/${customerId}/reports`, body);
  return response;
}

export async function copyReportRecord(report) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = { ...report, name: report.name };
  const response = await fetchWrapper.post(`customers/${customerId}/reports`, body);

  return response;
}

export async function saveReportFormat() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const reportState = state.report;
  const report = reportState.records[reportState.selectedRecordIndex];
  const reportId = report.id;

  const body = [
    //  { path: "/queryObject", op: "test", value: initialValue },
    { path: "/queryObject", op: "replace", value: report.queryObject },
  ];

  const response = await fetchWrapper.patch(`customers/${customerId}/reports/${reportId}`, body);

  return response;
}

export async function transformReportType(report, formatTypeId) {
  // debugger;
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.post(
    `customers/${customerId}/reports/${report.id}/transform?targetFormatType=${formatTypeId}`,
    report
  );
  store.dispatch(updateRecord({ report: response }));
  return response;
}

export async function saveReportFieldChanges(fieldName, value /*, initialValue*/) {
  // If no material change in value, do not save
  //if (value === initialValue || (!value && initialValue === "") || (value === "" && !initialValue)) return;

  const state = store.getState();
  const reportState = state.report;
  const customerId = state.app.customerId;

  const report = reportState.records[reportState.selectedRecordIndex];
  const reportId = report.id;

  const body = [
    //{ path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/reports/${reportId}`, body);

  store.dispatch(updateSimpleFieldValue({ reportId, fieldName, value }));
}

export async function saveReportOwnerType(ownerType, ownerId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const reportState = state.report;

  const report = reportState.records[reportState.selectedRecordIndex];
  const reportId = report.id;

  const body = [];

  if (report.ownerType !== ownerType) {
    body.push({ path: "/ownerType", op: "test", value: report.ownerType });
    body.push({ path: "/ownerType", op: "replace", value: ownerType });
  } else if (ownerType !== OwnerTypes.ACCOUNT && ownerType !== OwnerTypes.GROUP) return;

  switch (ownerType) {
    case OwnerTypes.ACCOUNT:
      if (!idsAreEqual(report.accountId, ownerId)) {
        body.push({ path: "/accountId", op: "test", value: report.accountId });
        body.push({ path: "/accountId", op: "replace", value: ownerId });
      }
      break;
    case OwnerTypes.GROUP:
      if (!idsAreEqual(report.groupId, ownerId)) {
        body.push({ path: "/groupId", op: "test", value: report.groupId });
        body.push({ path: "/groupId", op: "replace", value: ownerId });
      }
      break;
    default:
  }
  await fetchWrapper.patch(`customers/${customerId}/reports/${reportId}`, body);
  store.dispatch(updateOwnership({ reportId, ownerType, ownerId }));
}

export async function deleteReport(reportId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.delete(`customers/${customerId}/reports/${reportId}`);

  store.dispatch(deleteRecord({ reportId }));

  return response;
}

export async function executeReportRaw(reportId, outputType, queryObject) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.post(
    `customers/${customerId}/report/${reportId}/runraw?outputType=${outputType}`,
    queryObject
  );
  return response;
}

export async function executeReport(reportId, runParams) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.post(`customers/${customerId}/report/${reportId}/run`, runParams);
  return response;
}
