import { createCustomer, getAllCustomers, updateCustomerInCustomersList } from "api/security";
import PopupHeader from "components/global/PopupHeader";
import SuperSelect from "components/global/SuperSelect";
import Tooltip from "components/global/Tooltip";
import useComponentVisible from "components/global/useComponentVisible";
import { t } from "locale/dictionary";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { sortColumn } from "utilities/sort";
import { findById } from "utilities/stringAndArray";
import SecurityColumnHeader from "./SecurityColumnHeader";
import { labelTypes } from "utilities/constants";

export default function Customers() {
  const securityState = useSelector((state) => state.security);
  const appState = useSelector((state) => state.app);
  //const localeState = useSelector((state) => state.locale);
  const navigate = useNavigate();
  const [refModal, showModal, setShowModal] = useComponentVisible(false);
  const [currentCustomers, setCurrentCustomers] = useState([]);
  const [currentModal, setCurrentModal] = useState("ADDCUSTOMER");
  const [inputValue, setInputValue] = useState(null);
  const [currentlyEditing, setCurrentlyEditing] = useState({});
  const [sortParams, setSortParams] = useState({
    sortCol: "name",
    sortAsc: true,
  });

  const customerStatuses = getAllLookupValuesForSource("CustomerStatuses");

  //fetch customers on load & set to current state
  useEffect(() => {
    if (appState.isSysAdmin) {
      getAllCustomers();
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    setCurrentCustomers([...securityState.customers]);
    if (securityState.customers.length > 0) sortColumn("name", labelTypes.STRING, securityState.customers, sortParams);
  }, [securityState.customers]);

  const editCustomer = async () => {
    if (currentlyEditing.name !== currentlyEditing.previousName) {
      await updateCustomerInCustomersList(
        "name",
        currentlyEditing.previousName,
        currentlyEditing.name,
        currentlyEditing
      );
    }
    if (currentlyEditing.customerStatusId !== currentlyEditing.previousStatus) {
      await updateCustomerInCustomersList(
        "customerStatusId",
        currentlyEditing.previousStatus,
        currentlyEditing.customerStatusId,
        currentlyEditing
      );
    }

    setCurrentlyEditing({});
    setShowModal(false);
  };

  const addCustomer = async () => {
    if (!inputValue) return;
    const defaultStatusId = 1;
    await createCustomer({ name: inputValue, customerStatusId: defaultStatusId });
    setInputValue(null);
    setShowModal(false);
  };

  const handleUpdateSortParams = (sortedData, newSortParams) => {
    setCurrentCustomers(sortedData);
    setSortParams(newSortParams);
  };

  const handleAddInputChange = (value) => {
    setInputValue(value);
  };

  const handleEditInputChange = (value) => {
    const newEdit = { ...currentlyEditing };
    newEdit.name = value;
    setCurrentlyEditing(newEdit);
  };

  const handleStatusChange = (id) => {
    const newEdit = { ...currentlyEditing };
    newEdit.customerStatusId = id;
    setCurrentlyEditing(newEdit);
  };

  //sets modal type and opens modal
  const handleModalOpen = (modalType, customer) => {
    if (modalType === "EDITCUSTOMER") {
      setCurrentlyEditing({ ...customer, previousStatus: customer.customerStatusId, previousName: customer.name });
    }
    setCurrentModal(modalType);
    setShowModal(true);
  };

  const renderAddCustomerModalBody = (
    <>
      <div className="modal__selection-grid">
        <label htmlFor="addNameInput">{t("Name")}</label>
        <input
          className="modal__input"
          id="addNameInput"
          type="text"
          onChange={(e) => handleAddInputChange(e.target.value)}
        ></input>
      </div>
      <div className="modal__selection-row">
        <button onClick={() => addCustomer()}>Submit</button>
        <button onClick={() => setShowModal(false)}>Cancel</button>
      </div>
    </>
  );

  const renderEditCustomerModalBody = (
    <>
      <div className="modal__selection-grid">
        <label htmlFor="editNameInput">{t("Name")}</label>
        <input
          id="editNameInput"
          type="text"
          value={currentlyEditing.name}
          onChange={(e) => handleEditInputChange(e.target.value)}
          className="modal__input"
        ></input>

        <label htmlFor="statusSelect">{t("Status")}</label>
        <div className="modal__select">
          <SuperSelect
            options={customerStatuses}
            selectedOptionId={currentlyEditing.customerStatusId}
            onChange={handleStatusChange}
            id="statusSelect"
          />
        </div>
      </div>
      <div className="modal__selection-row">
        <button onClick={() => editCustomer()}>Submit</button>
        <button onClick={() => setShowModal(false)}>Cancel</button>
      </div>
    </>
  );

  const modalType = {
    ADDCUSTOMER: {
      title: "Add Customer",
      body: renderAddCustomerModalBody,
    },
    EDITCUSTOMER: {
      title: "Edit Customer",
      body: renderEditCustomerModalBody,
    },
  };

  const renderTableBody =
    currentCustomers?.length > 0 &&
    currentCustomers.map((customer) => (
      <tr className="security__table__body-row" key={customer.id}>
        <td className="security__table__body-row-cell link">
          <Link to={`/customers/${customer.id}`}>{customer.name}</Link>
        </td>
        <td className="security__table__body-row-cell">
          {findById(customerStatuses, customer.customerStatusId)?.displayValue}
        </td>
        <td className="security__table__body-row-cell">{customer.totalAccountsCount ?? 0}</td>
        <td className="security__table__body-row-cell">{customer.totalGroupsCount ?? 0}</td>
        <td className="security__table__body-row-cell">{customer.totalUsersCount ?? 0}</td>
        <td className="security__table__body-row-cell">{customer.totalMattersCount ?? 0}</td>
        <td
          className="security__table__body-row-cell security__table__body-row-cell--edit clickable"
          onClick={() => handleModalOpen("EDITCUSTOMER", customer)}
        >
          <Tooltip content="Edit this Customer" direction="right">
            <FaEdit />
          </Tooltip>
        </td>
      </tr>
    ));

  const renderMainTable = (
    <div className="security__table-container">
      <table className="security__table">
        <thead className="security__table__header">
          <tr className="security__table__header-row">
            <SecurityColumnHeader
              fieldName="name"
              fieldType={labelTypes.STRING}
              displayName="Name"
              tableData={currentCustomers}
              sortParams={sortParams}
              onUpdateSortParams={handleUpdateSortParams}
            />
            <SecurityColumnHeader
              fieldName="customerStatusId"
              fieldType={labelTypes.LOOKUP}
              displayName="Status"
              tableData={currentCustomers}
              sortParams={sortParams}
              onUpdateSortParams={handleUpdateSortParams}
            />
            <SecurityColumnHeader
              fieldName="totalAccountsCount"
              fieldType={labelTypes.DECIMAL}
              displayName="Accounts"
              tableData={currentCustomers}
              sortParams={sortParams}
              onUpdateSortParams={handleUpdateSortParams}
            />
            <SecurityColumnHeader
              fieldName="totalGroupsCount"
              fieldType={labelTypes.DECIMAL}
              displayName="Groups"
              tableData={currentCustomers}
              sortParams={sortParams}
              onUpdateSortParams={handleUpdateSortParams}
            />
            <SecurityColumnHeader
              fieldName="totalUsersCount"
              fieldType={labelTypes.DECIMAL}
              displayName="Users"
              tableData={currentCustomers}
              sortParams={sortParams}
              onUpdateSortParams={handleUpdateSortParams}
            />
            <SecurityColumnHeader
              fieldName="totalMattersCount"
              fieldType={labelTypes.DECIMAL}
              displayName="Records"
              tableData={currentCustomers}
              sortParams={sortParams}
              onUpdateSortParams={handleUpdateSortParams}
            />
            <th className="security__table__header-row-cell">{t("Edit")}</th>
          </tr>
        </thead>
        <tbody className="security__table__body">{renderTableBody}</tbody>
      </table>
    </div>
  );

  //main render
  return (
    <>
      {currentCustomers?.length > 0 && renderMainTable}
      <button className="flex-row-center" onClick={() => handleModalOpen("ADDCUSTOMER")}>
        {t("Add Customer")}
        &nbsp;
        <FaPlusCircle />
      </button>

      {showModal && (
        <div className="modal-mask">
          <div className="modal__selection-popup">
            <PopupHeader
              title={t(modalType[currentModal].title)}
              onClose={() => {
                setShowModal(false);
              }}
            />
            <div className="modal__selection-body">{modalType[currentModal].body}</div>
          </div>
        </div>
      )}
    </>
  );
}
