import { deleteBatchProject, updateBatchProject } from "api/project";
import { t } from "locale/dictionary";
import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { setAvailableCountries, setBatchCreateProject } from "redux/batchCreateSlice";
import { useDispatch, useSelector } from "react-redux";
import Delete from "components/global/Delete";
import { getAllCountriesAndCodes } from "utilities/countries";
import { matterTypes, projectStatuses } from "utilities/constants";
import { clearResults } from "redux/searchResultsSlice";
import { setBatchImportProject } from "redux/batchImportSlice";
import { clearCollapsedSections, setMatterTypesDisplay, setProject, updateProjectField } from "redux/projectSlice";
import { findById, idsAreEqual } from "utilities/stringAndArray";
import ProjectCreatePopup from "./ProjectCreatePopup";

function Project() {
  const searchResults = useSelector((state) => state.searchResults).currentSearch;
  //const batchCreateState = useSelector((state) => state.batchCreate);
  //const batchImportState = useSelector((state) => state.batchImport);
  const projectState = useSelector((state) => state.project);
  const countryGroups = useSelector((state) => state.customer).countryGroups;
  const appState = useSelector((state) => state.app);
  const lookupState = useSelector((state) => state.lookup);
  const locale = useSelector((state) => state.locale);
  const allMatterTypes = appState.allMatterTypes;
  const dispatch = useDispatch();

  const [projectName, setProjectName] = useState("");
  const [matterTypesFiltered, setMatterTypesFiltered] = useState([]);
  const [selectedMatterTypeId, setSelectedMatterTypeId] = useState(null);

  const [allCountriesAndCodes, setAllCountriesAndCodes] = useState([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isShowingCreatePopup, setIsShowingCreatePopup] = useState(false);

  const project = projectState.project;
  const matterTypesDisplay = projectState.matterTypesDisplay;

  useEffect(() => {
    if (searchResults?.results) dispatch(clearResults());
  }, []);

  useEffect(() => {
    setSelectedMatterTypeId(project?.project_MatterTypeId ?? selectedMatterTypeId);
    if (project?.project_MatterTypeId && countryGroups && matterTypesFiltered?.length > 0)
      setCountryFilters(project.project_MatterTypeId);
  }, [project?.project_MatterTypeId, countryGroups, matterTypesFiltered]);

  useEffect(() => {
    setProjectName(project?.project_ProjectName ?? projectName);
  }, [project?.project_ProjectName]);

  useEffect(() => {
    const allCountriesLookup = getAllCountriesAndCodes();
    if (allCountriesLookup?.length > 0) {
      setAllCountriesAndCodes(
        allCountriesLookup.map((country) => ({
          id: country.id,
          displayValue: country.displayName,
        }))
      );
    }
    if (lookupState?.global?.length > 0) {
      const matterTypesFilteredLocal = allMatterTypes?.filter(
        (mt) =>
          idsAreEqual(mt.id, matterTypes.DESIGNS) ||
          idsAreEqual(mt.id, matterTypes.PATENT) ||
          idsAreEqual(mt.id, matterTypes.TRADEMARK)
      );
      const matterTypesFilteredDisplayLocal = matterTypesFilteredLocal.map((mt) => ({
        id: mt.id,
        displayValue: locale.translations[mt.translationCode],
      }));

      dispatch(setMatterTypesDisplay(matterTypesFilteredDisplayLocal));
      setMatterTypesFiltered(matterTypesFilteredLocal);
    }
  }, [lookupState, allMatterTypes]);

  const setCountryFilters = (matterTypeId) => {
    //debugger;
    const matterType = findById(matterTypesFiltered, matterTypeId);
    const countryGroupForMatterType = matterType && findById(countryGroups, matterType.countryGroupId);
    let availableCountries = countryGroupForMatterType
      ? countryGroupForMatterType.memberCountries.map((id) => {
          const country = findById(allCountriesAndCodes, id);
          return { id: country.id, displayValue: country.displayValue };
        })
      : [];
    if (availableCountries.length === 0) availableCountries = allCountriesAndCodes;
    dispatch(setAvailableCountries(availableCountries));
  };

  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

  const handleProjectNameUpdate = () => {
    if (project?.id) {
      updateBatchProject(project.id, "project_ProjectName", projectName);
      dispatch(updateProjectField({ fieldName: "project_ProjectName", value: projectName }));
    }
    setIsEditingName(false);
  };

  const handleProjectDelete = () => {
    deleteBatchProject(project.id);
    setProjectName("");
    setSelectedMatterTypeId(null);
    dispatch(clearResults());
    dispatch(setBatchCreateProject(null));
    dispatch(setBatchImportProject(null));
    dispatch(setProject(null));
    dispatch(clearCollapsedSections());
  };

  const handleClearActiveProject = (showCreatePopup) => {
    dispatch(setProject(null));
    dispatch(setBatchCreateProject(null));
    dispatch(setBatchImportProject(null));
    dispatch(clearCollapsedSections());
    setProjectName("");
    setSelectedMatterTypeId(null);
  };

  const handleCreateProjectClick = () => {
    setIsShowingCreatePopup(true);
    handleClearActiveProject();
  };

  const renderProjectName = isEditingName ? (
    <input
      className="project__input"
      type="text"
      value={project && projectName}
      onChange={handleProjectNameChange}
      onBlur={handleProjectNameUpdate}
      placeholder={t("Enter a name for this Project")}
      onKeyUp={(e) => e.key === "Enter" && handleProjectNameUpdate()}
    />
  ) : (
    <>
      {project?.project_ProjectName.length > 0 && (
        <div className="project__name">
          <span>{project.project_ProjectName}</span>
          <span className="link" onClick={() => setIsEditingName(true)}>
            {t("Edit")}
          </span>
        </div>
      )}
    </>
  );

  const renderAbandonButton = (
    <div className="flex-row-center">
      <div className="button">
        <Delete message="Abandon this project and lose all saved data" onConfirm={() => handleProjectDelete()}>
          {t("Abandon Project")}
        </Delete>
      </div>
    </div>
  );

  return (
    <>
      <div className="project">
        <div className="project__row project__row--space-between">
          <div className="flex-row-center">
            <button onClick={handleCreateProjectClick}>{t("Create Project")}</button>
            <button onClick={handleClearActiveProject}>{t("Browse Existing Projects")}</button>
          </div>
          {renderProjectName}
          <div className="project__type">
            {findById(matterTypesDisplay, project?.project_MatterTypeId)?.displayValue}
          </div>
          {project && project.project_Status !== projectStatuses.MATTER_GENERATED && renderAbandonButton}
        </div>
      </div>
      {isShowingCreatePopup && <ProjectCreatePopup onClose={() => setIsShowingCreatePopup(false)} />}
    </>
  );
}

Project.propTypes = {
  matterTypes: PropTypes.array,
};

export default Project;
