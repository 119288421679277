// Utility functions for the manipulation and display of strings

import { t } from "locale/dictionary";
import { labelTypes } from "./constants";
import { getCountryNameFromIsoCode, getCountryNamesFromIsoList } from "./countries";
import { getLookupValueForField } from "./datafield";

export function compareByFieldValue(a, b, fieldName, dataType, asc) {
  //if (!a || !a[fieldName] || !b || b[fieldName]) return 0; // THIS BREAKS SORT!
  let aValue = a[fieldName];
  let bValue = b[fieldName];

  if (fieldName.toLowerCase().includes("country")) {
    aValue = getCountryNameFromIsoCode(a.countries[0]);
    bValue = getCountryNameFromIsoCode(b.countries[0]);
  } else {
    if (typeof aValue === "undefined" || typeof bValue === "undefined") {
      console.log(`Cannot sort on fieldName: ${fieldName}`);
      return 0;
    }
    switch (dataType) {
      case labelTypes.STRING:
      case labelTypes.UNKNOWN: {
        aValue = (aValue ?? "").toLowerCase();
        bValue = (bValue ?? "").toLowerCase();
        break;
      }
      case labelTypes.LOOKUP: {
        aValue = getLookupValueForField(fieldName, aValue);
        bValue = getLookupValueForField(fieldName, bValue);
        break;
      }
      default: // Dates and integers (and booleans?) don't require additional preparation
        break;
    }
  }
  if (aValue < bValue) {
    return asc ? -1 : 1;
  }
  if (aValue > bValue) {
    return asc ? 1 : -1;
  }
  return 0;
}

export function compareByValue(a, b) {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
}

export function compareByMatterImageSortIndex(a, b) {
  if (a.matterImage_SortIndex < b.matterImage_SortIndex) {
    return -1;
  }
  if (a.matterImage_SortIndex > b.matterImage_SortIndex) {
    return 1;
  }
  return 0;
}

export function compareByFieldSortIndex(a, b) {
  if (a.sortIndex < b.sortIndex) {
    return -1;
  }
  if (a.sortIndex > b.sortIndex) {
    return 1;
  }
  return 0;
}

export function compareByFieldWeighting(a, b) {
  if (a.sortIndex && b.sortIndex && a.sortIndex === b.sortIndex) {
    return compareByDisplayValue(a, b, "displayName");
  }
  if (a.sortIndex && b.sortIndex) {
    return compareByFieldSortIndex(a, b);
  }
  if (a.sortIndex && !b.sortIndex) {
    return -1;
  }
  if (!a.sortIndex && b.sortIndex) {
    return 1;
  }

  return compareByDisplayValue(a, b, "displayName");
}

export function compareByName(a, b) {
  return compareByDisplayValue(a, b, "name");
}

export function compareByDisplayName(a, b) {
  return compareByDisplayValue(a, b, "displayName");
}

export function compareByDisplayValueAsc(a, b) {
  return compareByDisplayValue(a, b, "displayValue");
}

export function compareByDisplayValueDesc(a, b) {
  return compareByDisplayValue(b, a, "displayValue");
}

export function compareByIdAsc(a, b) {
  return compareByDisplayValue(a, b, "id");
}

export function getFieldCompareFn(field) {
  // Special case for NICE class sorting
  if (field.fieldName === "corsearch_Class") {
    return compareByIdAsc;
  } else {
    return compareByDisplayValueAsc;
  }
}

function compareByDisplayValue(a, b, property) {
  // console.log("🚀 ~ compareByDisplayValue ~  b:", b);
  // console.log("🚀 ~ compareByDisplayValue ~ a:", a);
  // console.log("🚀 ~ compareByDisplayValue ~ property:", property);
  try {
    return a[property].localeCompare(b[property], undefined, { sensitivity: "base" });
  } catch {
    return 0;
  }
}

export function compareByObjectFieldName(array, fieldName, isAscending) {
  return array.sort((a, b) => {
    const isFieldAString = typeof a[fieldName] === "string" && typeof b[fieldName] === "string";
    const comparisonResult = isFieldAString
      ? a[fieldName].localeCompare(b[fieldName], undefined, { sensitivity: "base" })
      : a[fieldName] - b[fieldName];
    return isAscending ? comparisonResult : -comparisonResult;
  });
}

export function compareByReferenceArrayFieldName(
  fieldName,
  arrayToSort,
  referenceArray,
  arrayToSortIdentifier,
  referenceArrayIdentifier,
  isAscending
) {
  //This sort uses "referenceArray" to sort the "arrayToSort" by "fieldName" - the reference array determines the order of the array to sort.
  //The "referenceArrayIdentifier" and the "arrayToSortIdentifier" are used to match the objects in the two arrays.
  // This function is useful when you need to sort an array of objects based on the order of elements
  //in another array and the elements are objects with multiple properties
  return arrayToSort.sort((a, b) => {
    //Find the item in the reference array that matches the current item in the array to sort
    const itemA = referenceArray.find((item) => item[referenceArrayIdentifier] === a[arrayToSortIdentifier]);
    const itemB = referenceArray.find((item) => item[referenceArrayIdentifier] === b[arrayToSortIdentifier]);

    // Handle undefined values
    if (itemA === undefined && itemB === undefined) return 0;
    if (itemA === undefined) return isAscending ? 1 : -1;
    if (itemB === undefined) return isAscending ? -1 : 1;

    //Check if the field is a string and sort accordingly
    const isFieldAString = typeof itemA?.[fieldName] === "string" && typeof itemB?.[fieldName] === "string";
    const comparisonResult = isFieldAString
      ? itemA?.[fieldName].localeCompare(itemB?.[fieldName], undefined, { sensitivity: "base" })
      : itemA?.[fieldName] - itemB?.[fieldName];
    return isAscending ? comparisonResult : -comparisonResult;
  });
}

// Returns a true if any of the words in the string starts with the required match
export function anyWordStartsWith(stringToMatch, inputString) {
  // First check to see if there is an exact match
  if (stringToMatch.includes(inputString)) return true;
  // Now check for individual words
  const stringArray = stringToMatch.split(/[-\s]/);
  return stringArray.some((s) => s.startsWith(inputString)) || stringToMatch.startsWith(inputString);
}

// Show partial match highlight of a string
export function showPartialMatchHighlight(stringToDisplay, stringArrayToMatch) {
  if (!stringToDisplay || !stringArrayToMatch || stringArrayToMatch.length === 0) return [stringToDisplay, "", ""];
  let outputArray = [];
  stringArrayToMatch.forEach((stringToMatch) => {
    if (stringToMatch.length < 2) return;
    const startIndex = stringToDisplay.toLowerCase().indexOf(stringToMatch.toLowerCase());
    if (startIndex === -1) return;

    const endIndex = startIndex + stringToMatch.length;
    outputArray.push(stringToDisplay.substring(0, startIndex)); // First part non-matching, non-highlighted
    outputArray.push(stringToDisplay.substring(startIndex, endIndex)); // Second part, the matching section to be highlighted
    outputArray.push(stringToDisplay.substring(endIndex)); // Remaining part, non-highlighted
  });
  return outputArray.length > 1 ? outputArray : [stringToDisplay, "", ""];
}

export function getBooleanDisplayValue(dataValue) {
  if (dataValue === true) return t("Yes");
  if (dataValue === false) return t("No");
  return "";
}

// set panel id
export function getPanelIdString(sectionName) {
  return `panel_${sectionName.replaceAll(" ", "_")}`;
}

// Convert array buffer (typically from uploaded file) to base64 string
export function stringArrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

// Convert base64 string to blob for download to the client browser
export function base64ToBlob(base64data) {
  const byteCharacters = atob(base64data);
  const byteNumbers = new ArrayBuffer(byteCharacters.length);
  const byteArray = new Uint8Array(byteNumbers);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }
  var blob = new Blob([byteArray], {
    type: "text/plain;charset=utf-8",
  });
  return blob;
}

// Format Control Id to provide unique ids for all controls on edit page even when multiple records are being browsed
export function formatControlId(keyProps) {
  return (
    keyProps.controlId ??
    `${keyProps.record.id}${keyProps.field.fieldName}${keyProps.rowData ? keyProps.rowData.id : ""}`
  );
}

export function formatControlIdExplicit(recordId, fieldName, rowDataId) {
  return `${recordId}${fieldName}${rowDataId}`;
}

export function splitAndCleanStringToArray(stringToSplit) {
  // TODO: Fix regex to enable variable delimiter
  let returnArray = stringToSplit.split(/[,]+/);
  returnArray = returnArray.map((inputString) => inputString.trim());
  returnArray = returnArray.filter((inputItem) => inputItem.length > 0);
  return returnArray;
}

export function compareBySortCol(a, b, field, sortAscLocal) {
  const fieldToSort = field.fieldName;
  // If it's a lookup type, then we need to sort by the display value of that selected lookup value in the user's language
  const lookupDisplayItemA = a.lookupDisplayValues.find((ldv) => ldv.fieldName === fieldToSort);
  const lookupDisplayItemB = b.lookupDisplayValues.find((ldv) => ldv.fieldName === fieldToSort);

  let sortValueA = null;
  let sortValueB = null;
  if (lookupDisplayItemA && lookupDisplayItemB) {
    sortValueA =
      lookupDisplayItemA.lookupDisplayValue.length > 0 ? lookupDisplayItemA.lookupDisplayValue.toLowerCase() : "";
    sortValueB =
      lookupDisplayItemB.lookupDisplayValue.length > 0 ? lookupDisplayItemB.lookupDisplayValue.toLowerCase() : "";
  }
  // For composite objects in string form, sort by the first character of the first child field of the first child object
  else if (field.objectProps) {
    const firstChildObjectA = a[fieldToSort][0];
    sortValueA = firstChildObjectA?.[field.objectProps.propsList[0]];
    const firstChildObjectB = b[fieldToSort][0];
    sortValueB = firstChildObjectB?.[field.objectProps.propsList[0]];
  }
  // For multiple countries sort by the first character of the translated name of the first country
  else if (fieldToSort === "countries") {
    const firstCountryIsoCodeA = a[fieldToSort][0];
    sortValueA = getCountryNamesFromIsoList([firstCountryIsoCodeA]).displayValue;
    const firstCountryIsoCodeB = b[fieldToSort][0];
    sortValueB = getCountryNamesFromIsoList([firstCountryIsoCodeB]).displayValue;
  }
  // Class sorted numeric then alphabetic
  else if (fieldToSort === "matterGoods_Class") {
    sortValueA = a[fieldToSort];
    sortValueB = b[fieldToSort];
    const sortValueANum = Number(sortValueA);
    const sortValueBNum = Number(sortValueB);

    if (isNaN(sortValueANum) && isNaN(sortValueBNum)) {
      if (typeof sortValueA === "string") sortValueA = sortValueA.toLowerCase();
      if (typeof sortValueB === "string") sortValueB = sortValueB.toLowerCase();
    } else if (!isNaN(sortValueANum) && !isNaN(sortValueBNum)) {
      if (sortValueANum < sortValueBNum) {
        return sortAscLocal ? -1 : 1;
      }
      if (sortValueANum > sortValueBNum) {
        return sortAscLocal ? 1 : -1;
      }
      return 0;
    } else if (!isNaN(sortValueANum)) {
      return sortAscLocal ? -1 : 1;
    } else {
      return sortAscLocal ? 1 : -1;
    }
  }
  // Sort by base type (string, date)
  else {
    sortValueA = a[fieldToSort];
    sortValueB = b[fieldToSort];
    if (typeof sortValueA === "string") sortValueA = sortValueA.toLowerCase();
    if (typeof sortValueB === "string") sortValueB = sortValueB.toLowerCase();
  }

  if (sortValueA < sortValueB || (!sortValueA && sortValueB)) {
    return sortAscLocal ? -1 : 1;
  }
  if (sortValueA > sortValueB || (sortValueA && !sortValueB)) {
    return sortAscLocal ? 1 : -1;
  }
  return 0;
}

export function deDuplicateStringsWithSameStartingWord(array) {
  const uniqueStrings = [];
  const seenWords = new Set();
  let duplicateCount = 0;

  array.forEach((item) => {
    if (typeof item === "string") {
      const firstWord = item.split(" ")[0];
      if (!seenWords.has(firstWord)) {
        uniqueStrings.push(item);
        seenWords.add(firstWord);
      } else {
        duplicateCount++; // Increment the count for each duplicate
      }
    }
  });

  return { uniqueStrings, duplicateCount };
}

// ID equivalence and matching functions

// Helper function to check if a value can be converted to a valid number
const isConvertibleToNumber = (value) => !isNaN(value) && !isNaN(parseFloat(value));

// Check if two IDs are equal, even if they are of different types
// This is to successfully trap ids that might be returned from the DB as strings, but are expected to be numbers
export const idsAreEqual = (id1, id2) => {
  // Check if both are convertible to numbers
  if (isConvertibleToNumber(id1) && isConvertibleToNumber(id2)) {
    return Number(id1) === Number(id2);
  } else {
    // If not, return a strict equality check
    return id1 === id2;
  }
};

// const createIdMatcher = (method) => (array, id) => {
//   if (!array || array.length === 0) {
//     return method === "some" ? false : method === "findIndex" ? -1 : null;
//   }
//   const matchFunction =
//     typeof array[0] === "object" && array[0] !== null && "id" in array[0]
//       ? (item) => idsAreEqual(item.id, id)
//       : (arrayId) => idsAreEqual(arrayId, id);

//   return (
//     array[method](matchFunction) ||
//     (method === "find" || method === "filter" ? null : method === "findIndex" ? -1 : false)
//   );
// };

// export const hasMatchingId = createIdMatcher("some");
// export const findById = createIdMatcher("find");
// export const findIndexById = createIdMatcher("findIndex");

// NOTE: Very insidious bug - do not use the protoype method createIdMatcher on the array objects in redux
// as they behaving inconsistently (probably due to unusual/nested form), so to be sure we'll use explicit functions as below

export const hasMatchingId = (array, id) => {
  if (!array || array.length === 0) return false;
  if (typeof array[0] === "object" && array[0] !== null && "id" in array[0]) {
    return array.some((item) => idsAreEqual(item.id, id));
  } else {
    return array.some((arrayId) => idsAreEqual(arrayId, id));
  }
};

export const findById = (array, id) => {
  if (!array || array.length === 0) return null;
  if (typeof array[0] === "object" && array[0] !== null && "id" in array[0]) {
    return array.find((item) => idsAreEqual(item.id, id)) || null;
  } else {
    return array.find((arrayId) => idsAreEqual(arrayId, id)) || null;
  }
};

export const findIndexById = (array, id) => {
  if (!array || array.length === 0) return -1;
  if (typeof array[0] === "object" && array[0] !== null && "id" in array[0]) {
    return array.findIndex((item) => idsAreEqual(item.id, id));
  } else {
    return array.findIndex((arrayId) => idsAreEqual(arrayId, id));
  }
};

export const filterOutOnId = (array, id) => {
  if (!array || array.length === 0) return null;
  if (typeof array[0] === "object" && array[0] !== null && "id" in array[0]) {
    return array.filter((item) => !idsAreEqual(item.id, id)) || null;
  } else {
    return array.filter((arrayId) => !idsAreEqual(arrayId, id)) || null;
  }
};

// Determine whether subset is a subset of superset (i.e. all elements of the subset are in the superset)
export function isSubset(subset, superset) {
  return subset.every((element) => superset.includes(element));
}

// Determine whether subset has any unique elements (i.e. no elements are in the superset)
export function hasUniqueElement(subset, superset) {
  return subset.some((element) => !superset.includes(element));
}

// Determine whether two arrays have any common elements
export function hasCommonElement(array1, array2) {
  return array1.some((element) => array2.includes(element));
}

// Determine whether two arrays are disjoint sets (i.e. they have no elements in common)
export function areDisjoint(array1, array2) {
  return !array1.some((element) => array2.includes(element));
}
